import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchParents } from '../actions/parentActions';
import { Button, Typography, ListItem, ListItemText, Divider, Paper, Container, Select, MenuItem, Checkbox } from '@mui/material';

const ParentComp = () => {
    const [parentList, setParentList] = useState([]);
    const [selectedDomains, setSelectedDomains] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectAllDomains, setSelectAllDomains] = useState(false);
    const [responseLoading, setResponseLoading] = useState(false);
    const [filterCompany, setFilterCompany] = useState('');
    const [filteredCount, setFilteredCount] = useState(0);

    const dispatch = useDispatch();
    const data = useSelector(state => state.parentDomains.ParentData);
    const loading = useSelector(state => state.parentDomains.loading);

    const updateCompany = async (parent, domain) => {
        const siteUrl = `https://${domain}/wp-json/page-helper/v1/update-company`;
        setResponseLoading(true);

        try {
            const response = await axios.post(siteUrl, {
                company: parent,
            });
            console.log('Response:', response.data);
            dispatch(fetchParents());
            setResponseLoading(false);
        } catch (error) {
            console.error('Error:', error.response?.data || error.message);
            setResponseLoading(false);
        }
    };

    const handleParentCompaniesChange = (event) => {
        setSelectedCompany(event.target.value);
    };

    const handleDomainSelectChange = (event, domain) => {
        const { checked } = event.target;

        if (checked) {
            setSelectedDomains(prev => [...prev, domain]);
        } else {
            setSelectedDomains(prev => prev.filter(item => item !== domain));
        }
    };

    const handleSelectAllDomains = (event) => {
        const { checked } = event.target;
        setSelectAllDomains(checked);

        if (checked) {
            const allDomains = filteredData.map(item => item.domain);
            setSelectedDomains(allDomains);
        } else {
            setSelectedDomains([]);
        }
    };

    const handleBatchUpdate = async () => {
        if (selectedDomains.length === 0) {
            console.warn('No domains selected for batch update.');
            return;
        }

        if (!selectedCompany) {
            console.warn('No company selected for batch update.');
            return;
        }

        for (const domain of selectedDomains) {
            await updateCompany(selectedCompany, domain);
        }
    };

    const getParentList = async () => {
        try {
            const response = await axios.get('https://godzillanewz.com:8081/api/files');
            setParentList(response.data);
        } catch (error) {
            setParentList([]);
            console.error('Error:', error.response?.data || error.message);
        }
    };

    const handleParentCompanyChange = (event, domain) => {
        const { value } = event.target;
        updateCompany(value, domain);
        console.log(value, domain);
    };

    useEffect(() => {
        dispatch(fetchParents());
        getParentList();
    }, [dispatch]);

    const filteredData = filterCompany
        ? data.filter(item => item.company_name === filterCompany)
        : data;
        
    useEffect(() => {
        setFilteredCount(filteredData.length);
    }, [filterCompany, data, filteredData]);

    return (
        <Container maxWidth="md" style={{ marginTop: '20px' }}>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h4" gutterBottom>Domains Company Details</Typography>
                
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="div">Filter by Company</Typography>
                                <Select
                                    value={filterCompany}
                                    onChange={(e) => setFilterCompany(e.target.value)}
                                    style={{ minWidth: '200px' }}
                                    variant="outlined"
                                    displayEmpty
                                >
                                    <MenuItem value="">
                                        <em>All Companies</em>
                                    </MenuItem>
                                    {parentList.map(parent => (
                                        <MenuItem key={parent} value={parent}>
                                            {parent}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-start' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="div">New Company</Typography>
                                <Select
                                    value={selectedCompany}
                                    onChange={handleParentCompaniesChange}
                                    style={{ minWidth: '200px', marginRight: '10px' }}
                                    variant="outlined"
                                    displayEmpty
                                    disabled={responseLoading}
                                >
                                    <MenuItem value="">
                                        <em>Select Parent Company</em>
                                    </MenuItem>
                                    {parentList.map(parent => (
                                        <MenuItem key={parent} value={parent}>
                                            {parent}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleBatchUpdate}
                                disabled={loading || selectedDomains.length === 0 || !selectedCompany}
                                style={{ marginTop: '18px' }}
                            >
                                Set for selected
                            </Button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={selectAllDomains}
                                onChange={handleSelectAllDomains}
                            />
                            <ListItemText
                                primary="Select All Domains"
                            />
                        </ListItem>
                        <Typography style={{ alignSelf: "center", width: '10%' }} variant="subtitle1">{filteredCount} items</Typography>
                    </div>
                    {filteredData.map(item => (
                        <div key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
                            <ListItem style={{ flexGrow: 1 }}>
                                <Checkbox
                                    checked={selectedDomains.includes(item.domain)}
                                    onChange={(e) => handleDomainSelectChange(e, item.domain)}
                                />
                                <ListItemText
                                    primary={item.domain}
                                    secondary={`Company: ${item.company_name}`}
                                    key={item.id}
                                />
                            </ListItem>
                            <Select
                                value={parentList.includes(item.company_name) ? item.company_name : ''}
                                onChange={(e) => handleParentCompanyChange(e, item.domain)}
                                style={{ minWidth: '150px', marginLeft: '10px' }}
                                variant="outlined"
                                displayEmpty
                                disabled={responseLoading}
                            >
                                <MenuItem value="">
                                    <em>Select Parent Company</em>
                                </MenuItem>
                                {parentList.map(parent => (
                                    <MenuItem key={parent} value={parent}>
                                        {parent}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Divider />
                        </div>
                    ))}
                </div>
            </Paper>
        </Container>
    );
};

export default ParentComp;
