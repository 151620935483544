import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import LinkCheck from './LinkCheck';
import CopyCheck from './CopyCheck';

export default function HT() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange} aria-label="tabs example">
        <Tab label="Link Check" />
        <Tab label="Copy Check" />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {value === 0 && <LinkCheck />}
        {value === 1 && <CopyCheck />}
      </Box>
    </Box>
  );
}
