import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}`,
    withCredentials: true,
  });

  console.log("API: ", `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}`);

export const getUserInfo = async () => {
  try {
    const response = await api.get('/auth/user');
    return response.data.user;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const loginUser = async (username, password) => {
  try {
    const response = await api.post('/auth/login', {
      username,
      password,
      //mode: 'token'
    });

    const { token  } = response.data;
    if (token) {
      console.log('Token:', token);
    }
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const logoutUser = async () => {
  try {
    await api.post('/auth/logout');
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getHistory = async ( userTeam ) => {
  try {
    const response = await api.get('/api/table/history', {
      params: { team: userTeam }
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const updateHistory = async ( action, editedDomain, currentUser, editedColumnName, prevValue, editedValue, userTeam ) => {
  const currentDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
  try {
    const response = await api.post('/api/table/update/history', {
      action: action,
      domain: editedDomain,
      user: currentUser,
      date: currentDate,
      columnName: editedColumnName,
      previousValue: prevValue,
      nextValue: editedValue,
      team: userTeam,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const addNewRow = async ( newRow ) => {
  try {
    const response = await api.post('/api/table/add', newRow);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const updateTable = async ( editedRowId, editedColumnName, editedValue ) => {
  try {
    const response = await api.post('/api/table/update', {
      id: editedRowId,
      columnName: editedColumnName,
      value: editedValue,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deleteRowFromTable = async ( deleteRowId ) => {
  try {
    const response = await api.post('/api/table/delete', {
      id: deleteRowId
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const AllTable = async () => {
  try {
    const response = await api.get('/api/table/all');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const notSetTable = async () => {
  try {
    const response = await api.get('/api/table/not-set');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getIEXTable = async () => {
  try {
    const response = await api.get('/api/table/iex');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getPolarisTable = async () => {
  try {
    const response = await api.get('/api/table/polaris');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getDubaiTable = async () => {
  try {
    const response = await api.get('/api/table/dubai');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getCryptoTable = async () => {
  try {
    const response = await api.get('/api/table/crypto');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getLandingTable = async () => {
  try {
    const response = await api.get('/api/table/landing');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getAcademyTable = async () => {
  try {
    const response = await api.get('/api/table/academy');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getManagerTable = async (team) => {
  try {
    const response = await api.get('/api/table/manager', {
      params: {
        userTeam: team,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getMarketerTable = async (marketer) => {
  try {
    const response = await api.get('/api/table/marketer', {
      params: {
        marketer: marketer,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getMarketers = async (team) => {
  try {
    const response = await api.get('/api/marketers', {
      params: {
        userTeam: team,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getUsers = async () => {
  try {
    const response = await api.get('/api/users/all');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getAcademyUsers = async () => {
  try {
    const response = await api.get('/api/users/academy');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const updateClosedToSubmit = async (userId, updatedClosedToSubmit) => {
  try {
    const response = await api.post('/api/users/update-closed-to-submit', {
      userId,
      updatedClosedToSubmit
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data.message : error.message;
  }
};

export const getClosedToSubmit = async (userId) => {
  try {
    const response = await api.post('/api/users/get-closed-to-submit', {
      userId
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data.message : error.message;
  }
};

export const loadHomeWork = async (userID, domain, copy, tab) => {
  try {
    const response = await api.post('/api/users/load-homework', {
      userID,
      domain,
      copy,
      tab
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data.message : error.message;
  }
};

export const updateUser = async (userId, updatedData) => {
  try {
    const response = await api.put(`/api/users/updateUser/${userId}`, updatedData);;
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const addUserApi = async (newUser) => {
  try {
    const response = await api.post('/api/users/addUser', newUser);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deleteUserApi = async (userID) => {
  try {
    const response = await api.delete(`/api/users/deleteUser/${userID}`);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deleteUserRowFromExam = async (username) => {
  try {
    const response = await api.delete(`/api/users/deleteUserFromExam/${username}`);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const updateAccount = async (accountId, updatedAccount) => {
  try {
    const response = await axios.post('/api/update', {
      accountId,
      ...updatedAccount,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getAllParentCompanies = async () => {
  try {
    const response = await api.get('/api/parent-companies');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const academyDomains = async () => {
  try {
    const response = await api.get('/api/table/academy-domains');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const addNewAcademy = async ( newRow ) => {
  try {
    const response = await api.post('/api/table/add-academy', newRow);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deleteRowFromAcademy = async ( deleteRowId ) => {
  try {
    const response = await api.post('/api/table/delete-academy', {
      id: deleteRowId
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const updateAcademy = async ( editedRowId, editedColumnName, editedValue ) => {
  try {
    const response = await api.post('/api/table/update-academy', {
      id: editedRowId,
      columnName: editedColumnName,
      value: editedValue,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const allAcademyDeleteAll = async ( editedRowId, editedColumnName, editedValue ) => {
  try {
    const response = await api.post('/api/table/delete-all-academy-all');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const academyDomainsAll = async () => {
  try {
    const response = await api.get('/api/table/academy-domains-all');
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const addNewAcademyAll = async ( newRow ) => {
  try {
    const response = await api.post('/api/table/add-academy-all', newRow);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deleteRowFromAcademyAll = async ( deleteRowId ) => {
  try {
    const response = await api.post('/api/table/delete-academy-all', {
      id: deleteRowId
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const updateAcademyAll = async ( editedRowId, editedColumnName, editedValue ) => {
  try {
    const response = await api.post('/api/table/update-academy-all', {
      id: editedRowId,
      columnName: editedColumnName,
      value: editedValue,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const saveProjectSettings = async (settingType, settingKey, settingValue) => {
  try {
    const response = await api.post('/api/save-settings', {
      settingType,
      settingKey,
      settingValue,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to save settings';
  }
};

export const fetchProjectSettings = async () => {
  try {
    const response = await api.get('/api/get-settings');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch settings';
  }
};

export const getTeamsList = async () => {
  try {
    const response = await api.get('/api/settings/teams');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch teams list', error);
    throw error;
  }
};

export const getParentList = async () => {
  try {
    const response = await api.get('/api/settings/parent');
    const parents = await getParentsGodzilla();
    const combinedParentsList = [
      ...(response.data || []),
      ...parents
    ];
    return combinedParentsList;
  } catch (error) {
    console.error('Failed to fetch teams list', error);
    throw error;
  }
};

export const getUserGroupsList = async () => {
  try {
    const response = await api.get('/api/settings/groups');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch teams list', error);
    throw error;
  }
};

export const getTabsList = async () => {
  try {
    const response = await api.get('/api/settings/tabs');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch tabs list', error);
    throw error;
  }
};

export const saveTasksCell = async (rowName, username, tableName, value) => {
  try {
    const response = await api.post('/api/saveCell', {
      rowName,
      username,
      tableName,
      value,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to save cell', error);
    throw error;
  }
};

export const getTasksTableData = async (tabIndex) => {
  try {
    const response = await api.get('/api/getTasksTableData', {
      params: { tabIndex },
    });
    
    return response.data;
  } catch (error) {
    console.error('Failed to fetch table data', error);
    throw error;
  }
};

export const checkHomeWork = async (url, user, tab, index) => {
  try {
    const response = await api.get('/api/academy/check-homework', {
      params: { url, user, tab, index },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch table data', error);
    throw error;
  }
};

export const checkCopyHomeWork = async (html, user, tab, copy) => {
  try {
    const response = await api.post('/api/academy/check-copy-homework', {
      html, user, tab, copy
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch table data', error);
    throw error;
  }
};

export const getUserCopies = async (user, tab) => {
  try {
    const response = await api.get('/api/get-user-copies', {
      params: { user, tab },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch table data', error);
    throw error;
  }
};

export const getUserDomains = async (user, tab) => {
  try {
    const response = await api.get('/api/get-user-domains', {
      params: { user, tab },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch table data', error);
    throw error;
  }
};

export const getUserBroadcastDomains = async (user) => {
  try {
    const response = await api.get('/api/get-user-broadcast-domains', {
      params: { user },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch table data', error);
    throw error;
  }
};

export const getUserCopiesBroadcast = async (user, tab, domain) => {
  try {
    const response = await api.get('/api/get-user-copies-broadcast', {
      params: { user, tab, domain },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch table data', error);
    throw error;
  }
};

export const getGoogleAuthUrl = async () => {
  const response = await api.get('/auth/google/url');
  return response.data;
};

export const getParentsGodzilla = async () => {
  try {
    const response = await axios.get('https://godzillanewz.com:8081/api/files');
    return response.data;
  } catch (error) {
    return [];
  }
};

export const shuffleDomains = async () => {
  const response = await api.get('/api/shuffle');
  return response;
};

export const checkDomain = async (domain) => {
  const response = await api.get(`/api/check-domain?domain=${domain}`);
  return response;
};

export const submitHWLinks = async (userId, domain, copy, tabIndex, raw, result) => {
  try {
    const response = await api.post('/api/submit-hw', {
      userId,
      domain,
      copy,
      tabIndex,
      raw,
      result,
    });
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getHomeWorkTable = async (tab) => {
  const response = await api.post('/api/get-homeworks-tab', {tab});
  return response;
};