import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { loginUser } from '../services/api';
import { Box, TextField, Button, Snackbar, Alert } from '@mui/material';

const AuthForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(username, password);
      console.log(response.message);
      login();
    } catch (error) {
      console.error('login Error:', error);
      setError('Wrong password or login');
    }
  };

  const handleGoogleSignIn = () => {
    // Google auth redirect
    window.location.href = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/auth/google`;
  };

  const handleClose = () => {
    setError('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: 4,
          borderRadius: 1,
          boxShadow: 3,
          minWidth: '400px',
        }}
      >
        <TextField
          label="Login"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2 }}>
          Log In
        </Button>
        {/* Google */}
        <Button
          variant="outlined"
          color="primary"
          onClick={handleGoogleSignIn}
          sx={{ marginTop: 2 }}
        >
          Sign in with Google
        </Button>
      </Box>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AuthForm;
