import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsersData, updateUsersData, deleteUser } from '../../actions/UsersActions';
import { Card, CardContent, Typography, TextField, Button, CircularProgress, Snackbar, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UsersTab = ({ menuTeamOptions, menuGroupOptions }) => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.usersData.usersData);
  const loading = useSelector(state => state.usersData.loading);
  const error = useSelector(state => state.usersData.error);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editedUsername, setEditedUsername] = useState('');

  useEffect(() => {
    dispatch(fetchUsersData());
  }, [dispatch]);

  useEffect(() => {
    setSortedUsers(users);
  }, [users]);

  const handleUpdateUser = (userId, updatedUser) => {
    dispatch(updateUsersData(userId, updatedUser));
    setEditingUser(null);
    setSnackbarOpen(true);
  };

  const handleCancelEdit = () => {
    setEditingUser(null);
    setEditedUsername('');
  };

  const handleDeleteUser = (userID) => {
    dispatch(deleteUser(userID));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleCancelEdit();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            {error}
          </Alert>
        </Snackbar>
      ) : (
        <div className="user-list">
          {sortedUsers.map(user => (
            <Card key={user.id} className="user-card" sx={{ mb: 2 }}>
              <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '5px' }}>
                <Typography variant="div" component="div" style={{ marginRight: '10px' }}>
                  {user.id === editingUser ? (
                    <TextField
                      value={editedUsername}
                      onChange={(e) => setEditedUsername(e.target.value)}
                      autoComplete="off"
                    />
                  ) : (
                    user.username
                  )}
                </Typography>
                <Typography variant="body2" style={{ marginRight: '10px' }}>
                  {user.id === editingUser ? (
                    <TextField
                      value={user.login}
                      disabled
                      autoComplete="off"
                    />
                  ) : (
                    user.login
                  )}
                </Typography>
                <FormControl style={{ marginRight: '10px' }}>
                  {user.id === editingUser ? (
                    <>
                      <InputLabel>User Group</InputLabel>
                      <Select
                        value={user.usergroup}
                        onChange={(e) => setSortedUsers(prevUsers => prevUsers.map(prevUser => {
                          if (prevUser.id === user.id) {
                            return { ...prevUser, usergroup: e.target.value };
                          }
                          return prevUser;
                        }))}
                      >
                        {menuGroupOptions.map(option => (
                          <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      User Group: {user.usergroup}
                    </Typography>
                  )}
                </FormControl>
                <FormControl style={{ marginRight: '10px' }}>
                  {user.id === editingUser ? (
                    <>
                      <InputLabel>Team</InputLabel>
                      <Select
                        value={user.team}
                        onChange={(e) => setSortedUsers(prevUsers => prevUsers.map(prevUser => {
                          if (prevUser.id === user.id) {
                            return { ...prevUser, team: e.target.value };
                          }
                          return prevUser;
                        }))}
                      >
                        {menuTeamOptions.map(option => (
                          <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      Team: {user.team}
                    </Typography>
                  )}
                </FormControl>
                <div className="actions" style={{ marginLeft: 'auto', display: 'flex' }}>
                  {user.id === editingUser ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleUpdateUser(user.id, { ...user, username: editedUsername })}
                        style={{ marginRight: '10px' }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleCancelEdit}
                        style={{ marginRight: '10px' }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setEditingUser(user.id);
                        setEditedUsername(user.username);
                      }}
                      style={{ marginRight: '10px' }}
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteUser(user.id)}
                  >
                    Delete
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default UsersTab;
