import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsersData, updateUsersData, deleteUser, updateClosedToSubmitAction } from '../../actions/UsersAcademyActions';
import { TextField, Button, CircularProgress, Snackbar, MenuItem, Select, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UsersTab = ({ menuTeamOptions, menuGroupOptions }) => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.usersAcademyData.usersAcademyData);
  const loading = useSelector(state => state.usersAcademyData.loading);
  const error = useSelector(state => state.usersAcademyData.error);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editedUsername, setEditedUsername] = useState('');
  const [globalSwitches, setGlobalSwitches] = useState({ day1: false, day2: false, day3: false, day4: false, day5: false });

  useEffect(() => {
    dispatch(fetchUsersData());
  }, [dispatch]);

  useEffect(() => {
    setSortedUsers(users);
  }, [users]);

  const handleUpdateUser = (userId, updatedUser) => {
    dispatch(updateUsersData(userId, updatedUser));
    setEditingUser(null);
    setSnackbarOpen(true);
  };

  const handleCancelEdit = () => {
    setEditingUser(null);
    setEditedUsername('');
  };

  const handleDeleteUser = (userID) => {
    dispatch(deleteUser(userID));
  };

  const handleLockToggle = (user, userID, buttonIndex) => {
    const parsedClosedToSubmit = 
      !user.closed_to_submit || user.closed_to_submit === '' 
        ? {} 
        : typeof user.closed_to_submit === 'string'
          ? JSON.parse(user.closed_to_submit)
          : user.closed_to_submit;

    const updatedClosedToSubmit = {
      ...parsedClosedToSubmit,
      [`day${buttonIndex + 1}`]: !parsedClosedToSubmit[`day${buttonIndex + 1}`]
    };

    dispatch(updateClosedToSubmitAction(userID, updatedClosedToSubmit));

    setSortedUsers(prevUsers =>
      prevUsers.map(prevUser =>
        prevUser.id === userID ? { ...prevUser, closed_to_submit: updatedClosedToSubmit } : prevUser
      )
    );
  };

  const handleToggleAll = (buttonIndex) => {
    const fieldName = `day${buttonIndex + 1}`;
    const newGlobalState = !globalSwitches[fieldName];

    const updatedUsers = sortedUsers.map(user => {
      const parsedClosedToSubmit = 
        !user.closed_to_submit || user.closed_to_submit === '' 
          ? {} 
          : typeof user.closed_to_submit === 'string'
            ? JSON.parse(user.closed_to_submit)
            : user.closed_to_submit;

      const updatedClosedToSubmit = {
        ...parsedClosedToSubmit,
        [fieldName]: newGlobalState
      };
      dispatch(updateClosedToSubmitAction(user.id, updatedClosedToSubmit));
      return {
        ...user,
        closed_to_submit: updatedClosedToSubmit
      };
    });

    setSortedUsers(updatedUsers);
    setGlobalSwitches(prev => ({ ...prev, [fieldName]: newGlobalState }));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleCancelEdit();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            {error}
          </Alert>
        </Snackbar>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 150, textAlign: 'left' }}>User</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Username</TableCell>
                <TableCell style={{ textAlign: 'left' }}>User Group</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Team</TableCell>
                {[...Array(5)].map((_, index) => (
                  <TableCell key={index} style={{ textAlign: 'center' }}>
                    <Switch
                      checked={globalSwitches[`day${index + 1}`]}
                      onChange={() => handleToggleAll(index)}
                      color="primary"
                    />
                    Day {index + 1}
                  </TableCell>
                ))}
                <TableCell style={{ textAlign: 'right' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedUsers.map(user => (
                <TableRow key={user.id}>
                  <TableCell style={{ textAlign: 'left' }}>
                    {user.id === editingUser ? (
                      <TextField
                        value={editedUsername}
                        onChange={(e) => setEditedUsername(e.target.value)}
                        autoComplete="off"
                      />
                    ) : (
                      user.username
                    )}
                  </TableCell>
                  <TableCell style={{ width: 100, textAlign: 'left' }}>
                    {user.id === editingUser ? (
                      <TextField
                        value={user.login}
                        disabled
                        autoComplete="off"
                      />
                    ) : (
                      user.login
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: 'left' }}>
                    {user.id === editingUser ? (
                      <FormControl>
                        <InputLabel>User Group</InputLabel>
                        <Select
                          value={user.usergroup}
                          onChange={(e) => setSortedUsers(prevUsers => prevUsers.map(prevUser => {
                            if (prevUser.id === user.id) {
                              return { ...prevUser, usergroup: e.target.value };
                            }
                            return prevUser;
                          }))}
                        >
                          {menuGroupOptions.map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      user.usergroup
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: 'left' }}>
                    {user.id === editingUser ? (
                      <FormControl>
                        <InputLabel>Team</InputLabel>
                        <Select
                          value={user.team}
                          onChange={(e) => setSortedUsers(prevUsers => prevUsers.map(prevUser => {
                            if (prevUser.id === user.id) {
                              return { ...prevUser, team: e.target.value };
                            }
                            return prevUser;
                          }))}
                        >
                          {menuTeamOptions.map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      user.team
                    )}
                  </TableCell>
                  {[...Array(5)].map((_, index) => {
                    let buttonState;
                    if (user.closed_to_submit) {
                      const parsedClosedToSubmit = typeof user.closed_to_submit === 'string'
                        ? JSON.parse(user.closed_to_submit)
                        : user.closed_to_submit;
                  
                      buttonState = parsedClosedToSubmit[`day${index + 1}`];
                    } else{
                      buttonState = false;
                    }
                    return (
                      <TableCell key={index} style={{ textAlign: 'center' }}>
                        <Switch
                          checked={buttonState}
                          onChange={() => handleLockToggle(user, user.id, index)}
                          color="primary"
                        />
                      </TableCell>
                    );
                  })}
                  <TableCell style={{ textAlign: 'right' }}>
                    <div className="actions" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {user.id === editingUser ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleUpdateUser(user.id, { ...user, username: editedUsername })}
                            style={{ marginRight: '10px' }}
                          >
                            Save
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleCancelEdit}
                            style={{ marginRight: '10px' }}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setEditingUser(user.id);
                            setEditedUsername(user.username);
                          }}
                          style={{ marginRight: '10px' }}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default UsersTab;
