import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { addUser } from '../../actions/UsersActions';

const AddUserTab = ({ menuTeamOptions, menuGroupOptions }) => {
  const dispatch = useDispatch();
  const [newUser, setNewUser] = useState({ username: '', login: '', password: '', usergroup: '', team: '' });

  function generateRandomPassword(length = 10) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+[]{}|;:',.<>?";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }
  
  const handleAddUser = () => {
    const password = newUser.password || generateRandomPassword();
    const userWithPassword = { ...newUser, password };
    dispatch(addUser(userWithPassword));
    setNewUser({ username: '', login: '', password: '', usergroup: '', team: '' });
  };
  
  

  return (
    <div>
      <h3>Add New User</h3>
      <TextField
        label="Username"
        variant="outlined"
        value={newUser.username}
        onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
        fullWidth
        style={{ marginBottom: '10px' }}
        autoComplete="off"
      />
      <TextField
        label="Login"
        variant="outlined"
        value={newUser.login}
        onChange={(e) => setNewUser({ ...newUser, login: e.target.value })}
        fullWidth
        style={{ marginBottom: '10px' }}
        autoComplete="off"
      />
      <TextField
        label="Password (random if blank)"
        variant="outlined"
        type="password"
        value={newUser.password}
        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
        fullWidth
        style={{ marginBottom: '10px' }}
        autoComplete="off"
      />
      <FormControl fullWidth>
        <InputLabel>User Group</InputLabel>
        <Select
          value={newUser.usergroup}
          onChange={(e) => setNewUser({ ...newUser, usergroup: e.target.value })}
          sx={{mb: '10px'}}
        >
          {menuGroupOptions.map(option => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Team</InputLabel>
        <Select
          value={newUser.team}
          onChange={(e) => setNewUser({ ...newUser, team: e.target.value })}
          sx={{mb: '10px'}}
        >
          {menuTeamOptions.map(option => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleAddUser}>
        Add User
      </Button>
    </div>
  );
};

export default AddUserTab;
