import {
    fetchHistoryRequest,
    fetchHistorySuccess,
    fetchHistoryFailure,
  } from '../reducers/historyReducer';
  import { getHistory, updateHistory } from '../services/api';
  
  export const fetchHistoryData = ( team ) => {
    return async (dispatch) => {
      dispatch(fetchHistoryRequest());
      try {
        const response = await getHistory(team);
        dispatch(fetchHistorySuccess(response));
      } catch (error) {
        dispatch(fetchHistoryFailure(error.message));
      }
    };
  };

  export const updateHistoryData = ( action, editedDomain, currentUser, editedColumnName, prevValue, editedValue, userTeam ) => {
    return async (dispatch) => {
      try{
        await updateHistory(action, editedDomain, currentUser, editedColumnName, prevValue, editedValue, userTeam);//api update
        //dispatch(modifyTableData({ id, columnName, newValue }));//update in component
        dispatch(fetchHistoryData(userTeam));//upgrade history from DB
      }
      catch (error){
        console.error('Error updating data:', error);
      }
    };
  };