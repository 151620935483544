import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  usersData: [],
  loading: false,
  error: null,
};

const UsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsersRequest: state => {
      state.loading = true;
      state.error = null;
    },
    fetchUsersSuccess: (state, action) => {
      state.loading = false;
      state.usersData = action.payload;
    },
    fetchUsersFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    modifyUsersData: (state, action) => {
      state.usersData = action.payload;
    },
  },
});

export const {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
  modifyUsersData,
} = UsersSlice.actions;

export default UsersSlice.reducer;
