import React, { useRef, useEffect } from 'react';

function ShadowContent({ htmlContent }) {
  const shadowRootRef = useRef(null);

  useEffect(() => {
    if (!shadowRootRef.current.shadowRoot) {
      shadowRootRef.current.attachShadow({ mode: 'open' });
    }
    shadowRootRef.current.shadowRoot.innerHTML = htmlContent;
  }, [htmlContent]);

  return <div ref={shadowRootRef} />;
}

export default ShadowContent;
