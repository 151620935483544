import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Collapse,
  Box,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHistoryData } from '../actions/historyActions';
import { useAuth } from '../contexts/AuthContext';

const HistoryTable = () => {
  const [open, setOpen] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState({});
  const { user } = useAuth();

  const dispatch = useDispatch();
  const history = useSelector(state => state.history.historyData);

  const handleOpen = () => {
    setOpen(!open);
  };

  const toggleAccordion = (rowId) => {
    setAccordionOpen((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchHistoryData(user.team));
    }
  }, [user, dispatch]);

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Show History
      </Button>
      {open && (
        <TableContainer
          component={Paper}
          sx={{
            position: 'fixed',
            top: '64px',
            right: 0,
            maxWidth: '1200px',
            zIndex: 3,
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
            boxShadow: 3,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Action</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Domain</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>User</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Column</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>From</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>To</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{new Date(row.date).toISOString().replace('T', ' ').slice(0, 19)}</TableCell>
                  <TableCell>{row.action}</TableCell>
                  <TableCell>{row.domain}</TableCell>
                  <TableCell>{row.user}</TableCell>
                  <TableCell>{row.columnName}</TableCell>
                  <TableCell>
                    {row.action === 'Remove' ? (
                      <div>
                        <Button onClick={() => toggleAccordion(row.id)} variant="outlined" color="primary">
                          Info
                        </Button>
                        <Collapse in={accordionOpen[row.id]} timeout="auto" unmountOnExit>
                          <Box sx={{ padding: 1 }}>
                            {row.previousValue ? (
                              Object.entries(JSON.parse(row.previousValue)).map(([key, value]) => (
                                <Typography key={key} variant="body2">
                                  <strong>{key}:</strong> {value}
                                </Typography>
                              ))
                            ) : (
                              <Typography variant="body2">No previous value available.</Typography>
                            )}
                          </Box>
                        </Collapse>
                      </div>
                    ) : (
                      row.previousValue
                    )}
                  </TableCell>
                  <TableCell>{row.nextValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default HistoryTable;
