import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export default function Info() {

    const legendData = [
        { issue: "Completed", description: "Everything is correct" },
        { issue: "Img src", description: "Image not loaded" },
        { issue: "MergeTag", description: "Incorrect MergeTag" },
        { issue: "ID", description: "Incorrect ID" },
        { issue: "product=WSSP41 → product=WSSM41", description: "Wrong product copy in link, should be WSSM41" },
        { issue: "domain=253GWST → domain=253GWSP", description: "Domain abbreviation error" },
        { issue: "domain not found → domain=236DTC", description: "Domain not found or wrong domain tag in link" },
        { issue: "Tracking", description: "Incorrect tracking used in the link" },
        { issue: "type=F → type=BE", description: "Incorrect mailing type" },
    ];

    return (
        <div style={{ overflowX: 'auto', height: 'calc(100vh - 148px)' }}>
        <TableContainer component={Paper} style={{ marginTop: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>Issue</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {legendData.map((item, index) => (
                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                            <TableCell>{item.issue}</TableCell>
                            <TableCell>{item.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
}
