import React, { useState, useEffect } from 'react';
import {
  Skeleton,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAcademyAll, updateTableData, addNewTableRow, deleteByIdTableRow, deleteAll } from '../../../actions/AcademyAllActions';
import { updateHistoryData } from '../../../actions/historyActions';

const Table = ({ updateData, currentUser, userTeam, userGroup, searchValue, menuParentOptions, menuTeamOptions }) => {
  const columnsAdmin = [
    { name: 'Domain',
      header: 'Domain',
      minWidth: 220,
      style: 
        { position: 'sticky',
        left: '40px',
        backgroundColor: 'white',
        zIndex: 1,
        minWidth: 300 
        },
    editable: false },
    { name: 'Abbreviation', header: 'Abbreviation', minWidth: 300, editable: true },
    { name: 'tracking', header: 'Tracking', minWidth: 300, editable: true },
    { name: 'ESP', header: 'ESP', minWidth: 300, editable: true },
    { name: 'WpAccess', header: 'Wp Access', minWidth: 300, editable: true },
  ];

  const columnsIntern = [
    { name: 'Domain',
      header: 'Domain',
      minWidth: 220,
      style: 
        { position: 'sticky',
        left: '40px',
        backgroundColor: 'white',
        zIndex: 1,
        minWidth: 300 
        },
    editable: false },
    { name: 'tracking', header: 'Tracking', minWidth: 300, editable: false },
    { name: 'ESP', header: 'ESP', minWidth: 300, editable: false },
    { name: 'WpAccess', header: 'Wp Access', minWidth: 300, editable: false },
  ];

  let columns;
  switch (userGroup) {
    case 'admin':
      columns = columnsAdmin;
      break;
    case 'intern':
      columns = columnsIntern;
      break;
    default:
      columns = columnsIntern;
  }

  const [sortConfig, setSortConfig] = useState(null);
  const [editedRowId, setEditedRowId] = useState(null);
  const [editedColumnName, setEditedColumnName] = useState(null);
  const [editedDomain, setEditedDomain] = useState(null);
  const [prevValue, setPrevValue] = useState(null);
  const [editedValue, setEditedValue] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newRow, setNewRow] = useState('');
  const [tempValue, setTempValue] = useState('');

  const dispatch = useDispatch();
  const data = useSelector(state => state.AcademyAllTable.AcademyAllData);
  const loading = useSelector(state => state.AcademyAllTable.loading);
  const error = useSelector(state => state.AcademyAllTable.error);

  useEffect(() => {
    dispatch(fetchAcademyAll());
  }, [dispatch]);

  const sortBy = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    let sortableData = [...data];
    if (searchValue !== '') {
      sortableData = filteredData();
    }
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        const aValue = isNaN(a[sortConfig.key]) ? a[sortConfig.key] : parseFloat(a[sortConfig.key]);
        const bValue = isNaN(b[sortConfig.key]) ? b[sortConfig.key] : parseFloat(b[sortConfig.key]);

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  };

  const handleDoubleClick = (rowId, columnName) => {
    setEditedRowId(rowId);
    setEditedColumnName(columnName);
    const editedRow = data.find((row) => row.id === rowId);
    setEditedValue(editedRow[columnName]);
    setEditedDomain(editedRow.Domain);
    setPrevValue(editedRow[columnName]);
    setIsEditing(true);
  };

  const handleCellChange = (e, rowId, columnName) => {
    const newData = JSON.parse(JSON.stringify(data));
    const rowIndex = newData.findIndex((row) => row.id === rowId);
    newData[rowIndex][columnName] = e.target.value;
    setEditedValue(newData[rowIndex][columnName]);
    setTempValue(e.target.value);
  };

  const handleBulkEdit = (newValue) => {
    const newValues = newValue.split('\n').map(value => value.trim()).filter(value => value);

      const data = sortedData();

      // Длина массива newValues
      const newValuesLength = newValues.length;

      // Найдите индекс строки по ID
      const startIndex = data.findIndex(item => item.id === editedRowId);

      // Если индекс не найден или индекс выходит за пределы массива
      if (startIndex === -1 || startIndex + newValuesLength > data.length) {
        console.error('Не удалось найти строку или недостаточно данных.');
        return;
      }

      // Получите строки по индексу
      const rowsToUpdate = data.slice(startIndex, startIndex + newValuesLength);

      newValues.forEach((value, index) => {
        if (index < rowsToUpdate.length) {

          // Обновление данных в store
          dispatch(updateTableData(rowsToUpdate[index].id, editedColumnName, value));
          //dispatch(updateHistoryData('Edit', editedDomain, currentUser, editedColumnName, prevValue, value, userTeam));
        }
      });
      setEditedRowId(null);
      setEditedColumnName(null);
      setPrevValue(null);
      setEditedValue(null);
      setTempValue('');
  };

  const handleClickOutsideEdit = async () => {
    if (isEditing) {
      if ((editedColumnName === 'tracking' || editedColumnName === 'ESP') && editedValue.length > 0) {
        handleBulkEdit(editedValue);
      } else{
        setIsEditing(false);
        if (editedRowId !== null && window.getSelection().toString().length === 0) {
          if (prevValue !== editedValue) {
            const rowIndex = data.findIndex(item => item.id === editedRowId);
            const updatedRow = {
              ...data[rowIndex],
              [editedColumnName]: editedValue
            };
            const newData = [...data];
            newData[rowIndex] = updatedRow;
            dispatch(updateTableData(editedRowId, editedColumnName, editedValue));
            dispatch(updateHistoryData('Edit', editedDomain, currentUser, editedColumnName, prevValue, editedValue, userTeam));
            updateData();
          }
          setEditedRowId(null);
          setEditedColumnName(null);
          setPrevValue(null);
          setEditedValue(null);
          setTempValue('');
        }
      }
      

      
    } else {
      const isAnyFieldFilled = Object.values(newRow).some((value) => value.trim() !== '');
      if (isAnyFieldFilled) {
        handleAddRow();
      }
    }
  };

  const filteredData = () => {
    let filteredData = [...data];
    if (searchValue.length !== '') {
      filteredData = filteredData.filter((row) =>
        columns.some((column) => {
          const value = row[column.name];
          return value !== null && value !== undefined && typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase());
        })
      );
    }
    return filteredData.length > 0 ? filteredData : [{ id: null, message: "No matching results found" }];
  };

  const handleAddRow = () => {
    const domains = newRow.split('\n').map(domain => domain.trim()).filter(domain => domain);
    domains.forEach(domain => {
      dispatch(addNewTableRow({ Domain: domain }));
      dispatch(updateHistoryData('Add', domain, currentUser, 'Add', '', '', userTeam));
    });
    setNewRow('');
    updateData();
  };

  const handleDeleteRow = async (id, domain, row) => {
    const isConfirmed = window.confirm('Delete?');
    if (isConfirmed) {
      dispatch(updateHistoryData('Remove', domain, currentUser, '', JSON.stringify(row), '', userTeam));
      dispatch(deleteByIdTableRow(id));
      updateData();
    }
  };

  const handleDeleteAll = async (id, domain, row) => {
    const isConfirmed = window.confirm('Delete ALL?');
    if (isConfirmed) {
      dispatch(deleteAll());
    }
  };

  const renderCell = (row, column) => {
    /* let domainStatusColor = 'black';
    if (column.name === 'Domain') {
      domainStatusColor = row['isAvailable'] === 200 ? '#046a04' : 'red';
    } */
    if (isEditing && editedRowId === row.id && editedColumnName === column.name && editedColumnName !== null && column.editable) {
      return (
        <textarea
          defaultValue={row[column.name]}
          onChange={(e) => handleCellChange(e, row.id, column.name)}
          rows={row[column.name].split('\n').length}
          className={ 'table-input' }
          onClick={(e) => e.stopPropagation()}
        />
      );
    }
    return (
      <div /* style={{ color: domainStatusColor }} */>
        {row[column.name]}
      </div>
    );
  };

  const renderMenuItems = (options) => {
    return options.map((option) => (
      <MenuItem key={option} value={option}>{option}</MenuItem>
    ));
  };

  const renderAdditionalCell = (row, column) => {
    if (isEditing && editedRowId === row.id && editedColumnName === column && editedColumnName !== null) {
      switch (column) {
        case 'Parent':
          return (
            <Select
              value={tempValue}
              onChange={(e) => handleCellChange(e, row.id, 'Parent')}
              className={'table-input'}
              onClick={(e) => e.stopPropagation()}
            >
              {renderMenuItems(menuParentOptions)}
            </Select>
          );
        case 'Team':
          return (
            <Select
              value={tempValue}
              onChange={(e) => handleCellChange(e, row.id, 'Team')}
              className={'table-input'}
              onClick={(e) => e.stopPropagation()}
            >
              {renderMenuItems(menuTeamOptions)}
            </Select>
          );
          case 'fixed':
          return (
            <Select
              value={tempValue}
              onChange={(e) => handleCellChange(e, row.id, 'fixed')}
              className={'table-input'}
              onClick={(e) => e.stopPropagation()}
            >
              <MenuItem key={0} value="0">No</MenuItem>
              <MenuItem key={1} value="1">Yes</MenuItem>
            </Select>
          );
        default:
          return;
      }
    }
    switch (column) {
      case 'Parent':
        return row.Parent;
      case 'Team':
        return row.Team;
      case 'fixed':
        return row.fixed === 1 ? 'Yes' : 'No';
      default:
        return;
    }
  };

  return (
    <>
    <div style={{ overflowX: 'auto', height: 'calc(100vh - 112px)' }} onClick={handleClickOutsideEdit}>
      <table style={{ tableLayout: 'fixed' }} className={ 'table-main' } cellSpacing="0">
      
      <thead className={ 'table-head' }>
        <tr>
        <th onClick={() => sortBy('index')} className={ 'table-domain-number' } style={{ minWidth: 40 }}>
            # {sortConfig && sortConfig.key === 'index' && (
              <span>{sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽'}</span>
            )}
          </th>
          {columns.map((column) => (
            <th key={column.name} onClick={() => column.sortable !== false && sortBy(column.name)} className={column.columnClass} style={{ ...column.style, minWidth: column.minWidth }}>
              {column.header}
              {sortConfig && sortConfig.key === column.name && (
                <span>{sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽'}</span>
              )}
            </th>
          ))}
          <th className={ 'table-domain-number' } style={{ minWidth: 40 }}>
            <IconButton onClick={() => handleDeleteAll()} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </th>
        </tr>
      </thead>
      <tbody>
        {loading && !data.length ? (
          Array.from({ length: 10 }).map((_, index) => (
            <tr key={index}>
              <td className={'table-domain-number'}></td>
              {columns.map((column) => (
                <td key={`${index}-${column.name}`} className={column.columnClass} style={{ ...column.style, minWidth: column.minWidth }}>
                  <Skeleton animation="wave" width={`calc(${column.minWidth} - 40px)`} height={90} sx={{ mx: 'auto' }} />
                </td>
              ))}
              <td>
              <Skeleton animation="wave" variant="circle" width={24} height={24} sx={{ mx: 'auto' }} />
              </td>
            </tr>
          ))
        ) : error && !data.length ? (
          <div>Error: {error}</div>
        ) : (
          sortedData().map((row) => (
            <tr key={row.id}>
              <td className={ 'table-domain-number' }>{row.index}</td>
              {columns.map((column) => (
                column.name === 'Parent' || column.name === 'Team' || column.name === 'fixed' ? (
                  <td key={`${row.id}-${column.name}`} id={`cell-${row.id}-${column.name}`} onDoubleClick={() => { handleDoubleClick(row.id, column.name); setTempValue(row[column.name]); }} style={{ ...column.style, minWidth: column.minWidth }}>
                  <div>{renderAdditionalCell(row, column.name)}</div>
                </td>
                ) : (
                  <td key={`${row.id}-${column.name}`} id={`cell-${row.id}-${column.name}`} onDoubleClick={() => handleDoubleClick(row.id, column.name)} className={column.columnClass} style={{ ...column.style, minWidth: column.minWidth }}>
                    <div>{renderCell(row, column)}</div>
                  </td>
              )))}
                  {
                  userGroup === 'admin' && (
                    <td style={{ minWidth: "30" }}>
                    <IconButton onClick={() => handleDeleteRow(row.id, row.Domain, row)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </td>
                  )}
            </tr>
          ))
        )}
        {userGroup === 'admin' && (
            <tr>
              <td></td>
              <td>
                <textarea
                  value={newRow}
                  onChange={(e) => setNewRow(e.target.value)}
                  rows={newRow.split('\n').length}
                  className={'table-input'}
                  onClick={(e) => e.stopPropagation()}
                  style={{ minWidth: columns[0].minWidth }}
                />
              </td>
            </tr>
          )}
      </tbody>
    </table>
    </div>
    </>
  );
};

export default Table;
