import React from 'react';

const DefRoute = () => {

  return (
    <div>
      <h2>Hello world</h2>
    </div>
  );
};

export default DefRoute;
