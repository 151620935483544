import React, { useEffect, useState } from 'react';
import Spreadsheet from "react-spreadsheet";
import { getHomeWorkTable } from '../../../services/api';

export default function Links() {
    const [table, setTable] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getHomeWorkTable(0);
                const homeworkData = result.data;

                // Получаем уникальные идентификаторы пользователей
                const userIds = [...new Set(homeworkData.map(item => item.user_id))];
                
                // Получаем соответствующие usernames
                const userNames = [...new Set(homeworkData.map(item => item.username))];

                // Формируем заголовок для пользователей, используя usernames
                const header = [{ value: "" }, ...userNames.map(name => ({ value: name }))];
                const formattedData = [header];

                // Сохраним все ссылки для всех пользователей
                const userLinksMap = {};
                const userErrorsMap = {};

                // Заполним ссылки и ошибки для каждого пользователя
                homeworkData.forEach(item => {
                    const userId = item.user_id;
                    try {
                        const parsedResult = JSON.parse(item.result);
                        const jsonParsedResult = JSON.parse(parsedResult);
                        
                        // Сохраним ссылки для данного пользователя
                        if (!userLinksMap[userId]) {
                            userLinksMap[userId] = [];
                        }
                        userLinksMap[userId].push(...jsonParsedResult);

                        // Сохраним ошибки для данного пользователя
                        userErrorsMap[userId] = item.errors || ""; // Сохраняем ошибки
                    } catch (error) {
                        // Если парсинг не удался, можно сохранить ошибку или оставить пустым
                        if (!userLinksMap[userId]) {
                            userLinksMap[userId] = ["Parsing error"];
                        } else {
                            userLinksMap[userId].push("Parsing error");
                        }
                    }
                });

                // Найдем максимальное количество ссылок для всех пользователей
                const maxLinksCount = Math.max(...Object.values(userLinksMap).map(links => links.length));

                // Формируем строки для каждой ссылки
                for (let i = 0; i < maxLinksCount; i++) {
                    const row = [{ value: `Link ${i + 1}` }]; // Заголовок для каждой строки
                    userIds.forEach(userId => {
                        const userLinks = userLinksMap[userId] || [];
                        const linkData = userLinks[i] || null; // Получаем объект или null

                        if (linkData) {
                            // Проверяем, есть ли ошибки, исключая первый элемент (индекс)
                            const errors = linkData.slice(1).filter(link => link.verified === false);

                            if (errors.length > 0) {
                                // Формируем строку с ошибками
                                const errorMessages = errors.map(link => `${link.view}`).join(', ');
                                row.push({ value: errorMessages }); // Добавляем результат с ошибками
                            } else {
                                row.push({ value: "Completed" }); // Нет ошибок
                            }
                        } else {
                            row.push({ value: "" }); // Пустая ячейка, если нет данных
                        }
                    });
                    formattedData.push(row);
                }

                // Добавляем строку для ошибок
                const errorRow = [{ value: "Errors" }];
                userIds.forEach(userId => {
                    errorRow.push({ value: userErrorsMap[userId] || "" }); // Получаем ошибки для пользователя
                });
                formattedData.push(errorRow);

                // Добавляем классы для стилей
                const styledData = formattedData.map((row, rowIndex) =>
                    row.map((cell, cellIndex) => ({
                        ...cell,
                        className: (cellIndex === 0) ? 'results-head' : '' // Задаем стиль для первой колонки
                    }))
                );

                setTable(styledData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{ overflowX: 'auto', height: 'calc(100vh - 148px)' }}>
            <Spreadsheet data={table} />
        </div>
    );
}
