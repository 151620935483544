import React, { useState, memo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { updateHistoryData } from '../../../actions/historyActions';
import DeleteIcon from '@mui/icons-material/Delete';

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'collapse' }} />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const cellStyle = {
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  fontSize: '0.8rem',
  padding: 0,
};

const RowContent = memo(({ index, row, onCellClick, editedCell, handleEditChange, newValue, setNewValue, prevValue, setPrevValue, columns, currentUser, userTeam, dispatch, updateTableData, updateHistoryData, onDelete }) => (
  <React.Fragment>
    {columns.map((column, columnIndex) => (
      <TableCell
        key={`${row.id}-${column.name}`}
        align="center"
        sx={{
          ...cellStyle,
          width: column.width,
          minWidth: column.width,
          maxWidth: column.width,
          backgroundColor: 'white',
          position: columnIndex < 2 ? 'sticky' : 'relative',
          left: columnIndex === 0 ? 0 : columnIndex === 1 ? 40 : 'auto',
          zIndex: columnIndex < 2 ? 1 : 'auto',
        }}
        onDoubleClick={() => {
          const isEditableForUser =
            (column.editable === 'admin' && userTeam === 'admin') || 
            column.editable === true || 
            column.editable === undefined; //check
        
          if (column.name !== 'index' && column.name !== 'delete' && isEditableForUser) {
            setNewValue(row[column.name] || '');
            setPrevValue(row[column.name] || '');
            onCellClick(index, column.name);
          }
        }}
      >
        {editedCell.row === index && editedCell.column === column.name ? (
          <TextField
            defaultValue={row[column.name] || ''}
            onChange={(e) => handleEditChange(index, column.name, e.target.value)}
            onBlur={() => {
              onCellClick(null, null);
              if (prevValue !== newValue) {
                dispatch(updateTableData(row.id, column.name, newValue));
                dispatch(updateHistoryData('Edit', row.Domain, currentUser, column.name, prevValue, newValue, userTeam));
              }
            }}
            fullWidth
            autoFocus
            multiline
            rows={(typeof row[column.name] === 'string' ? row[column.name].split('\n').length : 1)}
            sx={{
              width: '100%',
              margin: 0,
              padding: 0,
              boxSizing: 'border-box',
            }}
          />
        ) : column.name === 'delete' ? (
          <DeleteIcon
            onClick={() => onDelete(row.id)}
            sx={{ cursor: 'pointer', color: 'red' }}
          />
        ) : (
          <div
            style={{
              height: `${row.lines * 20}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ddd',
              lines: row.lines
            }}
          >
            {row[column.name] || ''}
          </div>
        )}
      </TableCell>
    ))}
  </React.Fragment>
));

const VirtualizedTable = ({
  rows,
  loading,
  error,
  columns,
  currentUser,
  userTeam,
  menuTeamOptions,
  menuParentOptions,
  updateTableData,
  addNewTableRow,
  deleteByIdTableRow,
  //onDelete
}) => {
  const [editedCell, setEditedCell] = useState({ row: null, column: null });
  const [newValue, setNewValue] = useState('');
  const [prevValue, setPrevValue] = useState('');
  const [newRowValue, setNewRowValue] = useState('');
  const dispatch = useDispatch();

  const updatedColumns = [
    ...columns,
    //{ name: 'delete', width: 50, header: '' }
  ];

  const onCellClick = (row, column) => {
    setEditedCell({ row, column });
  };

  const handleEditChange = (rowIndex, column, value) => {
    setNewValue(value);
  };

  const handleDelete = (id) => {
    console.log(`Deleting row with id: ${id}`);
    dispatch(deleteByIdTableRow(id));
  };

  const handleAddRow = () => {
    const newRow = {
        Domain: newRowValue
    };
    columns.slice(1).forEach(column => {
        if (column.name !== 'Domain') {
          newRow[column.name] = null;
        }
      });
    console.log(newRow);
    dispatch(addNewTableRow(newRow));
    setNewRowValue('');
  };

  if (loading && rows.length === 2) {
    return (
      <Paper style={{ height: 'calc(100vh - 148px)', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper style={{ height: 'calc(100vh - 148px)', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>Error loading data</div>
      </Paper>
    );
  }

  return (
    <Paper style={{ height: 'calc(100vh - 148px)', width: '100%', overflowX: 'auto' }}>
      <TableVirtuoso
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => (
          <TableRow>
            {updatedColumns.map((column, columnIndex) => (
              <TableCell
                key={column.name}
                align="center"
                style={{
                  width: column.width,
                  minWidth: column.width,
                  maxWidth: column.width,
                  position: columnIndex < 2 ? 'sticky' : 'relative',
                  left: columnIndex === 0 ? 0 : columnIndex === 1 ? 40 : 'auto',
                  zIndex: columnIndex < 2 ? 1 : 'auto',
                  backgroundColor: 'white',
                  padding: 0,
                }}
              >
                <div style={{
                  border: '1px solid #ddd',
                  height: '24px',
                  padding: '10px'
                }}>
                {column.header}
                </div>
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(index, row) => (
          row.id === 'new-row' ? (
            <>
              <TableCell
                key={'add-new'}
                align="center"
                style={{
                  width: 40,
                  minWidth: 40,
                  maxWidth: 40,
                  backgroundColor: 'white',
                  zIndex: 1,
                  border: '1px solid #ddd',
                  position: 'sticky',
                  left: 0,
                  padding: 0,
                }}
              >
                New
                </TableCell>
              <TableCell
                style={{
                    backgroundColor: 'white',
                    zIndex: 1,
                    border: '1px solid #ddd',
                    position: 'sticky',
                    left: 72,
                  }}
              >
                <TextField
                  value={newRowValue}
                  onChange={(e) => setNewRowValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAddRow();
                    }
                  }}
                  fullWidth
                  placeholder="New Domain"
                />
              </TableCell>
              </>
          ) : (
            <RowContent
              index={index}
              row={row}
              onCellClick={onCellClick}
              editedCell={editedCell}
              handleEditChange={handleEditChange}
              newValue={newValue}
              setNewValue={setNewValue}
              prevValue={prevValue}
              setPrevValue={setPrevValue}
              columns={updatedColumns}
              currentUser={currentUser}
              userTeam={userTeam}
              dispatch={dispatch}
              updateTableData={updateTableData}
              updateHistoryData={updateHistoryData}
              onDelete={handleDelete}
            />
          )
        )}
      />
    </Paper>
  );
};

VirtualizedTable.propTypes = {
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  currentUser: PropTypes.string.isRequired,
  userTeam: PropTypes.string.isRequired,
  menuTeamOptions: PropTypes.array.isRequired,
  menuParentOptions: PropTypes.array.isRequired,
  updateTableData: PropTypes.func.isRequired,
  addNewTableRow: PropTypes.func.isRequired,
  deleteByIdTableRow: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};

export default VirtualizedTable;
