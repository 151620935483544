import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import AddUserTab from './users/AddUserTab';
import UsersTab from './users/UsersTab';
import AcademyTab from './users/AcademyTab';
import { getUserGroupsList } from '../services/api';

const Users = ({ menuTeamOptions }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [menuGroupOptions, setMenuGroupOptions] = useState([]);
  //const menuGroupOptions = ['admin', 'marketer', 'manager', 'intern'];
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const groups = await getUserGroupsList();
        setMenuGroupOptions(groups);
      } catch (error) {
        console.error('Failed to fetch groups', error);
      }
    };

    fetchTeams();
  }, []);

  return (
    <div>
      <h2>Users Management</h2>
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Add User" />
        <Tab label="Users" />
        <Tab label="Academy" />
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {currentTab === 0 && <AddUserTab menuTeamOptions={menuTeamOptions} menuGroupOptions={menuGroupOptions} />}
        {currentTab === 1 && <UsersTab menuTeamOptions={menuTeamOptions} menuGroupOptions={menuGroupOptions} />}
        {currentTab === 2 && <AcademyTab menuTeamOptions={menuTeamOptions} menuGroupOptions={menuGroupOptions} />}
      </Box>
    </div>
  );
};

export default Users;
