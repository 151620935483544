import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Select, MenuItem, TextField, Button, Box, FormControl, InputLabel, Grid, Snackbar, Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAuth } from '../../contexts/AuthContext';
import ShadowContent from './ShadowDom';
import { 
  getUserCopies,
  getUserDomains,
  checkCopyHomeWork,
  getUserBroadcastDomains,
  getUserCopiesBroadcast,
  checkHomeWork,
  submitHWLinks,
  getClosedToSubmit,
} from '../../services/api';

export default function HT() {
  const { user } = useAuth();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [text, setText] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [copies, setCopies] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState('');
  const [domains, setDomains] = useState([]);
  const [userDomains, setUserDomains] = useState([]);
  const [selectedDomainUsername, setSelectedDomainUsername] = useState('');
  const [selectedDomain, setSelectedDomain] = useState('');
  const settings = useSelector(state => state.settings);
  const fileInputRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(true);

  const getClosed = useCallback(async () => {
    try {
      const response = await getClosedToSubmit(user.id);
  
      // Проверяем, существует ли response и его свойство result
      if (response && response.result) {
        const parsedResult = JSON.parse(response.result);
        const tabKey = `day${selectedTabIndex + 1}`;
        setIsSubmitAllowed(parsedResult[tabKey] || false);
      } else {
        // Если response или result пустое, устанавливаем значение по умолчанию
        setIsSubmitAllowed(false);
      }
    } catch (error) {
      console.error('Error fetching closed to submit:', error);
      setIsSubmitAllowed(false); // Устанавливаем значение по умолчанию в случае ошибки
    }
  }, [user.id, selectedTabIndex]);

  useEffect(() => {
    const getDomainsAndCopies = async (user) => {
      try {
        setSelectedCopy('');
        setCopies([]);
        const copies = await getUserCopies(user, selectedTabIndex);
        const domains = await getUserDomains(user, selectedTabIndex);
        const copiesArray = copies ? copies.split('\n') : [];
        const domainsArray = domains ? domains.split('\n') : [];
        setUserDomains(domainsArray);
        setCopies(copiesArray);
      } catch (error) {
        console.error('Error fetching copies:', error);
      }
    };
    const getDomains = async (user) => {
      try {
        setSelectedCopy('');
        setDomains([]);
        const domains = await getUserBroadcastDomains(user);
        setDomains(domains);
      } catch (error) {
        console.error('Error fetching domains:', error);
      }
    };
    if (user.username) {
      if (selectedTabIndex === 0) {
        setCopies([]);
        setUserDomains([]);
      } else{
        getDomainsAndCopies(user.username);
      }
    }
    if (selectedTabIndex === 5) {
      getDomains(user.username);
    } else{
      setDomains([]);
    }
    getClosed();
  }, [selectedTabIndex, user, getClosed]);

  useEffect(() => {
    const getCopies = async () => {
      try {
        setSelectedCopy('');
        setCopies([]);
        const copies = await getUserCopiesBroadcast(selectedDomainUsername, selectedTabIndex, selectedDomain);
        const copiesArray = copies ? copies.split('\n') : [];
        setCopies(copiesArray);
      } catch (error) {
        console.error('Error fetching copies:', error);
      }
    };
    if (selectedDomain && selectedTabIndex === 5) {
      getCopies();
    }
  }, [selectedDomain, selectedDomainUsername, selectedTabIndex]);

  useEffect(() => {
      getClosed();
  }, [getClosed]);

  const handleTextChange = (event) => {
    setText(event.target.value);
    setHtmlContent(event.target.value);
  };

  function RenderDomain({ domain }) {
    return (
        domain.replace(/\.com$/, '')
    );
  }

  const handleDomainChange = (domain, username) => {
    setSelectedDomain(domain);
    setSelectedDomainUsername(username);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setText(e.target.result);
        setHtmlContent(e.target.result);
        // Сброс значения input после загрузки файла
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      };
      reader.readAsText(file);
    }
  };

  const handleTabChange = (event) => {
    setSelectedTabIndex(event.target.value);
    setSelectedCopy('');
    setSelectedDomain('');
    setText('');
    setHtmlContent('');
  };

  const handleCopyChange = (copy) => {
    setSelectedCopy(copy);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    let result;
    if (selectedTabIndex === 0) {
      //links here
      handleCheckLinksClick();
    } else{
      if (selectedTabIndex === 5){
        result = await checkCopyHomeWork(text, selectedDomainUsername, selectedTabIndex, selectedCopy);
        try {
          const submitResult = await submitHWLinks(user.id, selectedDomainUsername, selectedCopy, selectedTabIndex, text, result, 0);
          if (submitResult.success) {
            setSnackbarMessage('Copy loaded');
            setSnackbarSeverity('success');
          } else{
            setSnackbarMessage('Failed to submit copy');
            setSnackbarSeverity('error');
          }
        } catch (error) {
            setSnackbarMessage('Failed to submit copy');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      } else{
        result = await checkCopyHomeWork(text, user.username, selectedTabIndex, selectedCopy);
        try {
          const submitResult = await submitHWLinks(user.id, 'Domain', selectedCopy, selectedTabIndex, text, result, 0);
          if (submitResult.success) {
            setSnackbarMessage('Copy loaded');
            setSnackbarSeverity('success');
          } else{
            setSnackbarMessage('Copy not loaded');
            setSnackbarSeverity('error');
          }
        } catch (error) {
            setSnackbarMessage('Failed to submit copy');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      }
    }
  };

  const handleCheckLinksClick = async () => {
    const lines = text.split('\n').map(line => line.trim()).filter(line => line.length > 0);
    try {
      const results = await Promise.all(lines.map(async (url, index) => {
        const result = await checkHomeWork(url, user.username, selectedTabIndex, index);
        return result;
      }));

      const uniqueKeys = [...new Set(results.flatMap(result => Object.keys(result)))];

      const formattedData = results.map(result => {
        return uniqueKeys.map(key => ({
          value: result[key]?.value || '',
          verified: result[key]?.verified || false,
          expected: result[key]?.expected || '',
          view: result[key]?.view || '',
        }));
      });
      try {
        const submitResult = await submitHWLinks(user.id, 'Links', 'Links', selectedTabIndex, text, JSON.stringify(formattedData));
        if (submitResult.success) {
          setSnackbarMessage('Links loaded');
          setSnackbarSeverity('success');
        } else{
          setSnackbarMessage('Failed to submit links');
          setSnackbarSeverity('error');
        }
      } catch (error) {
          setSnackbarMessage('Failed to submit links');
          setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error processing URLs:', error);
    }
  };

  const isSubmitDisabled = 
  (selectedTabIndex === 0 && text.length === 0) || 
  (selectedTabIndex !== 0 && (text.length === 0 || selectedCopy === '')) || 
  isSubmitAllowed;

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12} md={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, m: 3 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="tab-select-label">Select Tab</InputLabel>
            <Select
              labelId="tab-select-label"
              value={selectedTabIndex !== null ? selectedTabIndex : ''}
              onChange={handleTabChange}
              label="Select Tab"
            >
              {settings.academy.tabNames.map((tab, index) => (
                <MenuItem key={index} value={index}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <Box>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                gap: '10px',
              }}
            >
              {domains.map((domain, index) => (
                <Button
                  key={index}
                  variant={selectedDomain === domain.value ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => handleDomainChange(domain.value, domain.username)}
                >
                  <RenderDomain domain={domain.value} />
                </Button>
              ))}
            </div>
          </Box>

          <Box>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                gap: '10px',
              }}
            >
              {copies.map((copy, index) => (
                <Button
                  key={index}
                  variant={selectedCopy === copy ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => handleCopyChange(copy)}
                >
                  {selectedTabIndex === 5 ? copy : userDomains[index]}
                </Button>
              ))}
            </div>
          </Box>

          <TextField
            label="Result"
            multiline
            rows={15}
            value={text}
            onChange={handleTextChange}
            variant="outlined"
            fullWidth
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <input
              accept=".html"
              style={{ display: 'none' }}
              id="upload-file"
              type="file"
              onChange={handleFileUpload}
              ref={fileInputRef}
            />
            <label htmlFor="upload-file">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                sx={{ marginTop: '20px', marginRight: '10px' }}
              >
                Upload HTML File
              </Button>
            </label>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        {htmlContent && (
          <Box
            sx={{
              padding: '20px',
              borderRadius: '4px',
              border: '1px solid #ddd',
              backgroundColor: '#f9f9f9',
              height: '100%',
              overflowY: 'auto',
            }}
          >
            {selectedTabIndex !== 0 && (
              <ShadowContent htmlContent={htmlContent} />
            )}
          </Box>
        )}
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
