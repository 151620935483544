import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { useDispatch } from 'react-redux';
import { updateLocalTableData, addNewlocalTableRow, deleteByIdTableRowLocal } from '../actions/tableActions';
import { updateLocalAcademyTableData } from '../actions/tableAcademyActions';
import { fetchHistoryData } from '../actions/historyActions';

const socketUrl = `${process.env.REACT_APP_WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_API_URL}`;

const useCustomWebSocket = (user) => {
  const dispatch = useDispatch();

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    user ? socketUrl : null,
    {
      shouldReconnect: (closeEvent) => {
        // Логгирование причин закрытия сокета
        //console.log('WebSocket closing reason:', closeEvent);
        return true;
      },
      onOpen: () => {
        //console.log('WebSocket opened at:', new Date());
      },
      onClose: (event) => {
        //console.log('WebSocket closed at:', new Date());
        //console.log('Close event code:', event.code);
        //console.log('Close event reason:', event.reason);
      },
      onError: (event) => {
        console.error('WebSocket error occurred at:', new Date(), 'Error:', event);
      },
    }
  );

  // Дополнительное логгирование для отслеживания состояния сокета
  useEffect(() => {
    switch (readyState) {
      case 0:
        console.log('WebSocket connecting...');
        break;
      case 1:
        console.log('WebSocket connected');
        break;
      case 2:
        console.log('WebSocket closing...');
        break;
      case 3:
        console.log('WebSocket closed');
        break;
      default:
        console.log('WebSocket unknown state:', readyState);
        break;
    }
  }, [readyState]);

  // Логгирование последнего сообщения
  useEffect(() => {
    if (lastMessage) {
      console.log('Last WebSocket message received at:', new Date(), 'Message:', lastMessage.data);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const messageData = JSON.parse(lastMessage.data);
      
      console.log('WebSocket message action:', messageData.action);
      
      if (messageData.action === 'update-all-domains') {
        if (user) {
          console.log('Received update-all-domains message for team:', user.team);
          dispatch(fetchHistoryData(user.team));
          dispatch(updateLocalTableData(messageData.id, messageData.columnName, messageData.value));
        }
      }

      if (messageData.action === 'add-all-domains') {
        if (user) {
          console.log('Received add-all-domains message for team:', user.team, 'New row:', messageData.newRow);
          dispatch(fetchHistoryData(user.team));
          dispatch(addNewlocalTableRow(messageData.newRow));
        }
      }

      if (messageData.action === 'delete-all-domains') {
        if (user) {
          console.log('Received delete-all-domains message for team:', user.team, 'Deleted ID:', messageData.id);
          dispatch(fetchHistoryData(user.team));
          dispatch(deleteByIdTableRowLocal(messageData.id));
        }
      }

      if (messageData.action === 'update-academy-exam') {
        if (user) {
          console.log('Received update-academy-exam message for team:', user.team, 'New value:', messageData.value);
          dispatch(fetchHistoryData(user.team));
          dispatch(updateLocalAcademyTableData(messageData.id, messageData.columnName, messageData.value));
        }
      }
    }
  }, [lastMessage, dispatch, user]);

  return { sendMessage, lastMessage, readyState };
};

export default useCustomWebSocket;
