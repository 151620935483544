import {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
} from '../reducers/UsersAcademyReducer';
import { getAcademyUsers, updateUser, addUserApi, deleteUserApi, updateClosedToSubmit } from '../services/api';

export const fetchUsersData = () => {
  return async (dispatch) => {
    dispatch(fetchUsersRequest());
    try {
      const response = await getAcademyUsers();
      dispatch(fetchUsersSuccess(response));
    } catch (error) {
      dispatch(fetchUsersFailure(error.message));
    }
  };
};

export const updateUsersData = (userId, updatedData) => {
  return async (dispatch) => {
    try {
      await updateUser(userId, updatedData);
      dispatch(fetchUsersData());
    } catch (error) {
      console.error('Ошибка при обновлении данных пользователя:', error);
    }
  };
};

export const addUser = (newUser) => {
  return async (dispatch) => {
    try {
      await addUserApi(newUser);
      dispatch(fetchUsersData());
    } catch (error) {
      console.error('Ошибка при добавлении пользователя:', error);
    }
  };
};

export const deleteUser = (userID) => {
  return async (dispatch) => {
    try {
      await deleteUserApi(userID);
      dispatch(fetchUsersData());
    } catch (error) {
      console.error('Ошибка при удалении пользователя:', error);
    }
  };
};

export const updateClosedToSubmitAction = (userID, updatedClosedToSubmit) => {
  return async (dispatch) => {
    try {
      await updateClosedToSubmit(userID, updatedClosedToSubmit);
    } catch (error) {
      console.error('Ошибка при обновлении прав:', error);
    }
  };
};