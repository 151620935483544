import React from 'react';

const NCAccounts = () => {
  
  return (
    <>
    <div>NC Accounts</div>
    </>
  );
};

export default NCAccounts;
