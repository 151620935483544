import {
  fetchTableRequest,
  fetchTableSuccess,
  fetchTableFailure,
} from '../reducers/parentReducer';
import { getAllParentCompanies } from '../services/api';

export const fetchParents = () => {
  return async (dispatch) => {
    dispatch(fetchTableRequest());

    try {
      const response = await getAllParentCompanies();
      dispatch(fetchTableSuccess(response));
    } catch (error) {
      dispatch(fetchTableFailure(error.message));
    }
  };
};
