import React, { useState, memo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { updateHistoryData } from '../../actions/historyActions';
import DeleteIcon from '@mui/icons-material/Delete';

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'collapse' }} />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const cellStyle = {
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  fontSize: '0.8rem',
  padding: 0,
};

const RowContent = memo(({
  index,
  row,
  onCellClick,
  editedCell,
  handleEditChange,
  newValue,
  setNewValue,
  prevValue,
  setPrevValue,
  columns,
  currentUser,
  userTeam,
  userGroup,
  dispatch,
  updateTableData,
  updateHistoryData,
  onDelete,
  menuParentOptions,
  menuTeamOptions,
  marketers,
}) => (
  <React.Fragment>
    {columns.map((column, columnIndex) => (
      <TableCell
        key={`${row.id}-${column.name}`}
        align="center"
        className={columnIndex < 2 ? 'sticky-column' : 'relative-column'}
        sx={{
          ...cellStyle,
          width: column.width,
          minWidth: column.width,
          maxWidth: column.width,
          backgroundColor: 'white',
          left: columnIndex === 0 ? 0 : columnIndex === 1 ? 40 : 'auto',
          zIndex: columnIndex < 2 ? 1 : 'auto',
        }}
        onDoubleClick={() => {
          const isEditableForUser = (
            (column.editable === 'manager' && userGroup === 'manager') ||
            (column.editable === 'marketer' && userGroup === 'manager') || 
            (column.editable === 'marketer' && userGroup === 'marketer') || 
            column.editable === true
          );
        
          if (column.name !== 'index' && column.name !== 'delete' && isEditableForUser) {
            setNewValue(row[column.name] || '');
            setPrevValue(row[column.name] || '');
            onCellClick(index, column.name);
          }
        }}
      >
        {editedCell.row === index && editedCell.column === column.name ? (
            column.name === 'Parent' || column.name === 'Team' ? (
              // Custom editor for Parent, Team columns
              <Select
                value={newValue}
                onChange={(e) => handleEditChange(index, column.name, e.target.value)}
                onBlur={() => {
                  onCellClick(null, null);
                  if (prevValue !== newValue) {
                    dispatch(updateTableData(row.id, column.name, newValue));
                    dispatch(
                      updateHistoryData(
                        'Edit',
                        row.Domain,
                        currentUser,
                        column.name,
                        prevValue,
                        newValue,
                        userTeam
                      )
                    );
                  }
                }}
                fullWidth
                autoFocus
              >
                {column.name === 'Parent' &&
                  menuParentOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                {column.name === 'Team' &&
                  menuTeamOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </Select>
              ) : column.name === 'marketer' ? (
                <Select
                  multiple
                  value={newValue === 'Not Set'
                    ? []
                    : Array.isArray(newValue) ? newValue
                    : (JSON.parse(newValue))
                  }
                  onChange={(e) => handleEditChange(index, column.name, e.target.value)}
                  onBlur={() => {
                    onCellClick(null, null);
                    if (JSON.stringify(prevValue) !== JSON.stringify(newValue)) {
                      dispatch(updateTableData(row.id, column.name, JSON.stringify(newValue)));
                      dispatch(
                        updateHistoryData(
                          'Edit',
                          row.Domain,
                          currentUser,
                          column.name,
                          prevValue,
                          JSON.stringify(newValue),
                          userTeam
                        )
                      );
                    }
                  }}
                  renderValue={(selected) =>
                    Array.isArray(selected) ? selected.join(', ') : ''
                  }
                  fullWidth
                  autoFocus
                >
                  {marketers.map((marketer) => (
                    <MenuItem key={marketer} value={marketer}>
                      <Checkbox checked={newValue.includes(marketer)} />
                      <ListItemText primary={marketer} />
                    </MenuItem>
                  ))}
                </Select>
            ) : (
              // Default editor for other columns
              <TextField
                defaultValue={row[column.name] || ''}
                onChange={(e) =>
                  handleEditChange(index, column.name, e.target.value)
                }
                onBlur={() => {
                  onCellClick(null, null);
                  if (prevValue !== newValue) {
                    dispatch(updateTableData(row.id, column.name, newValue));
                    dispatch(
                      updateHistoryData(
                        'Edit',
                        row.Domain,
                        currentUser,
                        column.name,
                        prevValue,
                        newValue,
                        userTeam
                      )
                    );
                  }
                }}
                fullWidth
                autoFocus
                multiline
                rows={row[column.name]?.split('\n').length}
                sx={{
                  width: '100%',
                  margin: 0,
                  padding: 0,
                  boxSizing: 'border-box',
                }}
              />
            )
          ) : column.name === 'delete' ? (
          <DeleteIcon
            onClick={() => onDelete(row.id, row.domain, row)}
            sx={{ cursor: 'pointer', color: 'red' }}
          />
        ) : column.name === 'marketer' ? (
          (() => {
            let selected;
            try {
              // Попробовать разобрать данные как JSON
              selected = JSON.parse(row[column.name]);
              if (!Array.isArray(selected)) {
                selected = [];
              }
            } catch {
              // Если JSON не удалось разобрать, считать данные как строку
              selected = row[column.name]
                ? row[column.name].split(',').map(item => item.trim())
                : [];
            }
      
            return (
              <div
                style={{
                  height: `${row.lines * 20}px`,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '1px solid #ddd',
                  lines: row.lines
                }}
              >
                {selected.map((marketer, index) => (
                  <span key={marketer}>
                    {marketer}
                  </span>
                ))}
              </div>
            );
          })()
        ) : (
          <div
            style={{
              height: `${row.lines * 20}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ddd',
              lines: row.lines
            }}
          >
            {row[column.name] || ''}
          </div>
        )}
      </TableCell>
    ))}
  </React.Fragment>
));

const VirtualizedTable = ({
  rows,
  loading,
  error,
  columns,
  currentUser,
  userTeam,
  userGroup,
  menuTeamOptions,
  menuParentOptions,
  updateTableData,
  addNewTableRow,
  deleteByIdTableRow,
  marketers,
  //onDelete
}) => {
  const [editedCell, setEditedCell] = useState({ row: null, column: null });
  const [newValue, setNewValue] = useState('');
  const [prevValue, setPrevValue] = useState('');
  const [newRowValue, setNewRowValue] = useState('');
  const dispatch = useDispatch();

  const updatedColumns = [
    ...columns,
    { name: 'delete', width: 50, header: '' }
  ];

  const onCellClick = (row, column) => {
    setEditedCell({ row, column });
  };

  const handleEditChange = (rowIndex, column, value) => {
    setNewValue(value);
  };

  const handleDelete = (id, domain, row) => {
    const isConfirmed = window.confirm('Delete?');
    if (isConfirmed) {
      dispatch(updateHistoryData('Remove', domain, currentUser, '', JSON.stringify(row), '', userTeam));
    dispatch(deleteByIdTableRow(id));
    }
  };

  const handleAddRow = () => {
    const newRow = {
        Domain: newRowValue
    };
    columns.slice(1).forEach(column => {
        if (column.name !== 'Domain') {
          newRow[column.name] = null;
        }
      });
    dispatch(addNewTableRow(newRow));
    setNewRowValue('');
  };

  const allRows = [...rows, { id: 'new-row', index: 'New', newColumn: newRowValue }];
  if (loading && allRows.length === 2) {
    return (
      <Paper style={{ height: 'calc(100vh - 148px)', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper style={{ height: 'calc(100vh - 148px)', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>Error loading data</div>
      </Paper>
    );
  }

  return (
    <Paper style={{ height: 'calc(100vh - 148px)', width: '100%', overflowX: 'auto' }}>
      <TableVirtuoso
        data={allRows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => (
          <TableRow>
            {updatedColumns.map((column, columnIndex) => (
              <TableCell
                key={column.name}
                align="center"
                className={columnIndex < 2 ? 'sticky-column' : 'relative-column'}
                style={{
                  width: column.width,
                  minWidth: column.width,
                  maxWidth: column.width,
                  left: columnIndex === 0 ? 0 : columnIndex === 1 ? 40 : 'auto',
                  zIndex: columnIndex < 2 ? 1 : 'auto',
                  backgroundColor: 'white',
                  padding: 0,
                }}
              >
                <div style={{
                  border: '1px solid #ddd',
                  height: '24px',
                  padding: '10px'
                }}>
                {column.header}
                </div>
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(index, row) => (
          row.id === 'new-row' ? (
            <>
              <TableCell
                key={'add-new'}
                align="center"
                style={{
                  width: 40,
                  minWidth: 40,
                  maxWidth: 40,
                  backgroundColor: 'white',
                  zIndex: 1,
                  border: '1px solid #ddd',
                  position: 'sticky',
                  left: 0,
                  padding: 0,
                }}
              >
                #
                </TableCell>
              <TableCell
                style={{
                    backgroundColor: 'white',
                    zIndex: 1,
                    border: '1px solid #ddd',
                    position: 'sticky',
                    left: 72,
                  }}
              >
                <TextField
                  value={newRowValue}
                  onChange={(e) => setNewRowValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAddRow();
                    }
                  }}
                  fullWidth
                  placeholder="New Domain"
                />
              </TableCell>
              </>
          ) : (
            <RowContent
              index={index}
              row={row}
              onCellClick={onCellClick}
              editedCell={editedCell}
              handleEditChange={handleEditChange}
              newValue={newValue}
              setNewValue={setNewValue}
              prevValue={prevValue}
              setPrevValue={setPrevValue}
              columns={updatedColumns}
              currentUser={currentUser}
              userTeam={userTeam}
              userGroup={userGroup}
              dispatch={dispatch}
              updateTableData={updateTableData}
              updateHistoryData={updateHistoryData}
              onDelete={handleDelete}
              menuParentOptions={menuParentOptions}
              menuTeamOptions={menuTeamOptions}
              marketers={marketers}
            />
          )
        )}
      />
    </Paper>
  );
};

VirtualizedTable.propTypes = {
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  currentUser: PropTypes.string.isRequired,
  userTeam: PropTypes.string.isRequired,
  menuTeamOptions: PropTypes.array.isRequired,
  menuParentOptions: PropTypes.array.isRequired,
  updateTableData: PropTypes.func.isRequired,
  addNewTableRow: PropTypes.func.isRequired,
  deleteByIdTableRow: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};

export default VirtualizedTable;
