import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, TextField, Button, IconButton, Checkbox, FormControlLabel, Snackbar, Alert } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { fetchProjectSettings, saveProjectSettings, shuffleDomains } from '../services/api';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [teamsList, setTeamsList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [parentsList, setParentsList] = useState([]);
  const [tabNames, setTabNames] = useState([]);
  const [tabActivation, setTabActivation] = useState([]);
  const [espArray, setEspArray] = useState([]);
  const [mailingTypeArray, setMailingTypeArray] = useState([]);
  const [footerArray, setFooterArray] = useState([]);
  const [broadcastSetting, setBroadcastSetting] = useState('');
  const [resultShow, setResultShow] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await fetchProjectSettings();
        setTeamsList(settings.global?.teams_list || []);
        setGroupsList(settings.global?.groups_list || []);
        setParentsList(settings.global?.parents_list || []);
        setTabNames(settings.academy?.tab_name || []);
        setTabActivation(settings.academy?.tab_activation || Array(settings.academy?.tab_name.length).fill(false));
        setResultShow(settings.academy?.result_show || Array(settings.academy?.tab_name.length).fill(false));
        setEspArray(settings.academy?.esp || []);
        setMailingTypeArray(settings.academy?.mailing_type || []);
        setFooterArray(settings.academy?.footer || Array(settings.academy?.tab_name.length).fill(''));
        setBroadcastSetting(settings.academy?.broadcast_days || '');
      } catch (error) {
        console.error('Failed to load settings:', error);
      }
    };

    loadSettings();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSave = async () => {
    try {
      await saveProjectSettings('global', 'teams_list', teamsList);
      await saveProjectSettings('global', 'groups_list', groupsList);
      await saveProjectSettings('global', 'parents_list', parentsList);
      await saveProjectSettings('academy', 'tab_name', tabNames);
      await saveProjectSettings('academy', 'tab_activation', tabActivation);
      await saveProjectSettings('academy', 'esp', espArray);
      await saveProjectSettings('academy', 'mailing_type', mailingTypeArray);
      await saveProjectSettings('academy', 'footer', footerArray);
      await saveProjectSettings('academy', 'broadcast_days', broadcastSetting);
      await saveProjectSettings('academy', 'result_show', resultShow);
      setSnackbarMessage('Settings saved successfully');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Failed to save settings');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  const handleShuffle = async () => {
    try {
      await shuffleDomains();
      setSnackbarMessage('Domains shuffled');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Failed to shuffle domains');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
    
  };

  const handleTeamsChange = (index, value) => {
    const newTeamsList = [...teamsList];
    newTeamsList[index] = value;
    setTeamsList(newTeamsList);
  };

  const handleGroupsChange = (index, value) => {
    const newGroupsList = [...groupsList];
    newGroupsList[index] = value;
    setGroupsList(newGroupsList);
  };

  const handleParentsChange = (index, value) => {
    const newParentsList = [...parentsList];
    newParentsList[index] = value;
    setParentsList(newParentsList);
  };

  const handleTabNameChange = (index, value) => {
    const newTabNames = [...tabNames];
    newTabNames[index] = value;
    setTabNames(newTabNames);
  };

  const handleTabActivationChange = (index) => {
    const newTabActivation = [...tabActivation];
    newTabActivation[index] = !newTabActivation[index];
    setTabActivation(newTabActivation);
  };

  const handleResultShowChange = (index) => {
    const newResultShow = [...resultShow];
    newResultShow[index] = !newResultShow[index];
    setResultShow(newResultShow);
  };

  const handleEspChange = (index, value) => {
    const newEspArray = [...espArray];
    newEspArray[index] = value;
    setEspArray(newEspArray);
  };

  const handleMailingTypeChange = (index, value) => {
    const newMailingTypeArray = [...mailingTypeArray];
    newMailingTypeArray[index] = value;
    setMailingTypeArray(newMailingTypeArray);
  };

  const handleDeleteTeam = (index) => {
    const newTeamsList = teamsList.filter((_, i) => i !== index);
    setTeamsList(newTeamsList);
  };

  const handleDeleteGroup = (index) => {
    const newGroupsList = groupsList.filter((_, i) => i !== index);
    setGroupsList(newGroupsList);
  };

  const handleDeleteParent = (index) => {
    const newParentsList = parentsList.filter((_, i) => i !== index);
    setParentsList(newParentsList);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="settings tabs">
        <Tab label="Global" />
        <Tab label="Academy" />
      </Tabs>
      <Box sx={{ padding: 3 }}>
        {activeTab === 0 && (
          <Box>
            <Typography variant="h6">Teams List</Typography>
            {teamsList.map((team, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <TextField
                  label={`Team ${index + 1}`}
                  value={team}
                  onChange={(e) => handleTeamsChange(index, e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <IconButton onClick={() => handleDeleteTeam(index)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button onClick={() => setTeamsList([...teamsList, ''])}>Add Team</Button>

            <Typography variant="h6" sx={{ marginTop: 2 }}>Groups List</Typography>
            {groupsList.map((group, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <TextField
                  label={`Group ${index + 1}`}
                  value={group}
                  onChange={(e) => handleGroupsChange(index, e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <IconButton onClick={() => handleDeleteGroup(index)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button onClick={() => setGroupsList([...groupsList, ''])}>Add Group</Button>

            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              Parents List
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
              Parents which not in files in Godzilla
            </Typography>
            {parentsList.map((parent, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <TextField
                  label={`Parent ${index + 1}`}
                  value={parent}
                  onChange={(e) => handleParentsChange(index, e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <IconButton onClick={() => handleDeleteParent(index)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button onClick={() => setParentsList([...parentsList, ''])}>Add Parent</Button>
          </Box>
        )}
        {activeTab === 1 && (
          <>
          <Box>
            <Typography variant="h6">Tab Settings</Typography>
            {tabNames.map((name, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: '#f5f5f5',
                  padding: 2,
                  marginBottom: 2,
                  borderRadius: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!tabActivation[index]}
                        onChange={() => handleTabActivationChange(index)}
                      />
                    }
                    label=""
                    sx={{ marginRight: 1 }}
                  />
                  <TextField
                    label={`Tab ${index + 1} Name`}
                    value={name}
                    onChange={(e) => handleTabNameChange(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Box>
                  
                {index === 0 && (
                  <TextField
                    label={`ESP for Tab ${index + 1}`}
                    value={espArray[index] || ''}
                    onChange={(e) => handleEspChange(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                )}
                <TextField
                  label={`Mailing Type for Tab ${index + 1}`}
                  value={mailingTypeArray[index] || ''}
                  onChange={(e) => handleMailingTypeChange(index, e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label={`Footer for Tab ${index + 1}`}
                  value={footerArray[index] || ''}
                  onChange={(e) => {
                    const newFooterArray = [...footerArray];
                    newFooterArray[index] = e.target.value;
                    setFooterArray(newFooterArray);
                  }}
                  fullWidth
                  margin="normal"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={resultShow[index] || false}
                      onChange={() => handleResultShowChange(index)}
                    />
                  }
                  label={`Show result`}
                />
              </Box>
            ))}
            <Box
              sx={{
                backgroundColor: '#f5f5f5',
                padding: 2,
                marginBottom: 2,
                borderRadius: 2,
              }}
            >
              <Typography variant="h6">Broadcast Settings</Typography>
              <TextField
                label="Broadcast Setting"
                value={broadcastSetting}
                onChange={(e) => setBroadcastSetting(e.target.value)}
                fullWidth
                margin="normal"
              />
            </Box>
          </Box>
          <Box>
          <Button variant="contained" color="primary" onClick={handleShuffle}>
            Reshuffle Domains
          </Button>
          </Box>
          </>
        )}


        <Box sx={{ marginTop: 3 }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;
