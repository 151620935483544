import React, { useState, useEffect, useMemo, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import { CircularProgress, TextField } from '@mui/material';
import { saveTasksCell, getTasksTableData } from '../../../services/api';
import { useSelector } from 'react-redux';

const predefinedRowsFirst = ['Домен', 'ESP', 'Продукт & Номер копії', 'Вид розсилки'];
const predefinedRowsSmall = ['Домен', 'Продукт & Номер копії', 'Вид розсилки'];

const columns = (users) => [
  {
    label: '',
    dataKey: 'rowName',
    sticky: true,
  },
  ...users.map((user) => ({
    label: user.username,
    dataKey: user.username,
  })),
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'collapse' }} />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent(users) {
  return (
    <TableRow>
      {columns(users).map((column) => (
        <TableCell
          key={`header-${column.dataKey}`}
          variant="head"
          align="center"
          style={{
            minWidth: column.sticky ? 120 : 200,
            position: column.sticky ? 'sticky' : 'static',
            left: column.sticky ? 0 : undefined,
            zIndex: column.sticky ? 1 : undefined,
            backgroundColor: 'white',
            border: '1px solid #ddd',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function EditableCell({ value, onChange, onBlur }) {
  const inputRef = useRef(null);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const cursorPosition = event.target.selectionStart;
    onChange(newValue);

    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    });
  };

  return (
    <TextField
      inputRef={inputRef}
      defaultValue={value}
      onChange={handleChange}
      onBlur={onBlur}
      fullWidth
      autoFocus
      multiline
      rows={6}
      sx={{
        minWidth: 400,
        fontSize: '0.8rem',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      }}
    />
  );
}

function rowContent(index, row, users, onCellClick, handleBlur, editedCell, handleEditChange, predefinedRows, usergroup, footerText) {
  const totalColumns = columns(users).length; // Определяем общее количество колонок

  return (
    <React.Fragment>
      {columns(users).map((column, columnIndex) => {
        const isFixedRow = index === predefinedRows.length;
        const isVisibleCell = isFixedRow ? columnIndex < 2 : true;
        const cellValue = isFixedRow && columnIndex === 0 ? 'Важлива умова' :
                          isFixedRow && columnIndex === 1 ? footerText : row[column.dataKey];
        const cellColSpan = isFixedRow && columnIndex === 1 ? totalColumns : 1; // Устанавливаем colSpan для второй фиксированной ячейки
        const isFooterCell = isFixedRow && columnIndex === 1;
        
        return isVisibleCell ? (
          <TableCell
            key={`${row.rowName}-${column.dataKey}`}
            align="center"
            sx={{
              position: column.sticky ? 'sticky' : 'static',
              left: column.sticky ? 0 : undefined,
              backgroundColor: column.sticky ? 'white' : undefined,
              border: '1px solid #ddd',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              fontSize: '0.8rem',
              padding: 0,
              zIndex: column.sticky || isFooterCell ? 2 : 1,
            }}
            colSpan={cellColSpan}
            onDoubleClick={() => {
              if (row.rowName !== 'ESP' && row.rowName !== 'Вид розсилки' && row.rowName !== '' && usergroup.userGroup === "admin") {
                onCellClick(index, column.dataKey);
              }
            }}
          >
            {editedCell.row === index && editedCell.column === column.dataKey ? (
              <EditableCell
                value={row[column.dataKey]}
                onChange={(value) => handleEditChange(row.rowName, column.dataKey, value)}
                onBlur={() => handleBlur(row.rowName, editedCell.column, row[column.dataKey])}
              />
            ) : (
              <div
                style={{
                  position: isFooterCell ? 'sticky' : 'static',
                  width: isFooterCell ? 'fit-content' : undefined,
                  left: isFooterCell ? '40%' : undefined,
                  padding: '16px',
                }}
              >
                {cellValue}
              </div>
            )}
          </TableCell>
        ) : null;
      })}
    </React.Fragment>
  );
}


export default function ReactVirtualizedTable({ users, tableName, index, usergroup }) {
  const [rows, setRows] = useState([]);
  const [editedCell, setEditedCell] = useState({ row: null, column: null });
  const [loading, setLoading] = useState(true);
  const [footerText, setFooterText] = useState('');

  const predefinedRows = useMemo(() => {
    switch (index) {
      case 0:
        return predefinedRowsFirst;
      default:
        return predefinedRowsSmall;
    }
  }, [index]);

  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTasksTableData(index);
        const formattedRows = predefinedRows.map((rowName) => {
          const rowData = { rowName };
          const footerArray = settings.academy.footer || [];
          setFooterText(footerArray[index] || '')
          if (rowName === 'ESP') {
            users.forEach((user) => {
              rowData[user.username] = settings.academy.esp[index]?.split(' ').join('\n') || '';
            });
          } else if (rowName === 'Вид розсилки') {
            users.forEach((user) => {
              rowData[user.username] = settings.academy.mailingType[index]?.split(' ').join('\n') || '';
            });
          } else {
            users.forEach((user) => {
              const cell = data.find(
                (item) => item.row_name === rowName && item.username === user.username
              );
              rowData[user.username] = cell ? cell.value : '';
            });
          }
          return rowData;
        });

        // Add the fixed row with specific cells
        formattedRows.push({ rowName: 'Фиксированная строка', 0: 'Фиксированная ячейка 1', 1: 'Фиксированная ячейка 2' });

        setRows(formattedRows);
      } catch (error) {
        console.error('Failed to load table data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [tableName, users, predefinedRows, index, settings.academy.esp, settings.academy.mailingType, settings.academy.footer]);

  const onCellClick = (row, column) => {
    setEditedCell({ row, column });
  };

  const handleBlur = (rowName, column, value) => {
    if (tableName === 'tracking') {
      const splitValues = value.split('\n');
      const updatedRows = [...rows];
      
      if (splitValues.length > 1 && rows.length > 1) {
        const startRow = rows.findIndex((row) => row.rowName === rowName);

        splitValues.forEach((subValue, index) => {
          const rowIndex = startRow + index;
          if (rowIndex < rows.length) {
            updatedRows[rowIndex][column] = subValue;
          }
        });

        setRows(updatedRows);
        saveTasksCell(rowName, column, index, splitValues[0]); 
      } else {
        saveTasksCell(rowName, column, index, value); 
      }
    } else {
      saveTasksCell(rowName, column, index, value); 
    }
    setEditedCell({ row: null, column: null });
  };

  const handleEditChange = (rowName, column, value) => {
    const newRows = [...rows];
    const rowIndex = newRows.findIndex((row) => row.rowName === rowName);
    if (rowIndex !== -1) {
      newRows[rowIndex][column] = value;
      setRows(newRows);
    }
  };

  if (loading) {
    return (
      <Paper style={{ height: 'calc(100vh - 148px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <TableVirtuoso
      data={rows}
      fixedHeaderContent={() => fixedHeaderContent(users)}
      itemContent={(index, row) => rowContent(index, row, users, onCellClick, handleBlur, editedCell, handleEditChange, predefinedRows, usergroup, footerText)}
      components={VirtuosoTableComponents}
      style={{ height: 'calc(100vh - 148px)' }}
    />
  );
}
