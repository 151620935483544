import React, { useState } from 'react';
import axios from 'axios';

const WhoisChecker = () => {
  const [domainsInput, setDomainsInput] = useState('');
  const [domainsInfo, setDomainsInfo] = useState([]);

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}`,
    withCredentials: true,
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const domainsArray = domainsInput.split('\n').map(domain => domain.trim()).filter(Boolean);
      const response = await api.post('/api/whois-check', { domains: domainsArray });
      const parsedDomains = response.data.map(domain => JSON.parse(domain));
      setDomainsInfo(parsedDomains);
    } catch (error) {
      console.error('Error fetching domains information:', error);
    }
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>WHOIS and DNS Records Checker</h1>
      <form onSubmit={handleFormSubmit}>
        <label htmlFor='domains'>Enter Domains:</label><br />
        <textarea
          id='domains'
          name='domains'
          rows='5'
          cols='50'
          value={domainsInput}
          onChange={(e) => setDomainsInput(e.target.value)}
          style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
        /><br />
        <input type='submit' value='Check Domains' style={{ marginTop: '8px' }} /><br /><br />
      </form>
      <div style={{ overflowX: 'auto' }}>
      {domainsInfo.length > 0 && (
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '16px' }}>
          <thead>
            <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'center' }}>
              <th style={tableHeaderStyle}>Domain Name</th>
              <th style={tableHeaderStyle}>Registered On</th>
              <th style={tableHeaderStyle}>Updated On</th>
              <th style={tableHeaderStyle}>Expires On</th>
              <th style={tableHeaderStyle}>Name Servers</th>
              <th style={tableHeaderStyle}>IP Address</th>
              <th style={tableHeaderStyle}>A Records</th>
              <th style={tableHeaderStyle}>TXT Records</th>
              <th style={tableHeaderStyle}>MX Records</th>
              <th style={tableHeaderStyle}>CNAME Records</th>
              <th style={tableHeaderStyle}>SOA Records</th>
              <th style={tableHeaderStyle}>PTR Records</th>
              <th style={tableHeaderStyle}>DKIM</th>
              <th style={tableHeaderStyle}>DMARC Record</th>
              <th style={tableHeaderStyle}>Subdomains</th>
              <th style={tableHeaderStyle}>Expiring Soon</th>
              <th style={tableHeaderStyle}>Sold</th>
              <th style={tableHeaderStyle}>Blocked</th>
            </tr>
          </thead>
          <tbody>
            {domainsInfo.map((domain, index) => (
              <tr key={index}>
                <td style={tableCellStyle}>{domain.domainname}</td>
                <td style={tableCellStyle}>{domain.registeredon}</td>
                <td style={tableCellStyle}>{domain.updatedon}</td>
                <td style={tableCellStyle}>{domain.expireson}</td>
                <td style={tableCellStyle} dangerouslySetInnerHTML={{ __html: domain.nameservers }}></td>
                <td style={tableCellStyle}>{domain.ip_address}</td>
                <td style={tableCellStyle}><pre style={preStyle}>{domain.a_records}</pre></td>
                <td style={tableCellStyle}><pre style={preStyle}>{domain.txt_records}</pre></td>
                <td style={tableCellStyle}><pre style={preStyle}>{domain.mx_records}</pre></td>
                <td style={tableCellStyle}><pre style={preStyle}>{domain.cname_records}</pre></td>
                <td style={tableCellStyle}><pre style={preStyle}>{domain.soa_records}</pre></td>
                <td style={tableCellStyle}><pre style={preStyle}>{domain.ptr_records}</pre></td>
                <td style={tableCellStyle}><pre style={preStyle}>{domain.dkim_record}</pre></td>
                <td style={tableCellStyle}><pre style={preStyle}>{domain.dmarc_record}</pre></td>
                <td style={tableCellStyle}>
                  {Array.isArray(domain.subdomains) ? (
                    domain.subdomains.map((subdomain, subIndex) => (
                      <div key={subIndex}>
                        <strong>Subdomain:</strong> {subdomain.subdomain}<br />
                        <strong>CNAME Record:</strong> {subdomain.cname_record}<br />
                        <strong>A Record:</strong> {subdomain.a_record}<br />
                        <strong>MX Record:</strong> {subdomain.mx_record}<br />
                        <strong>TXT Record:</strong> {subdomain.txt_record}<br />
                        <strong>DMARC Record:</strong> {subdomain.dmarc_record}<br />
                        <strong>DKIM Record:</strong> {subdomain.dkim_record}<br />
                        <strong>PTR Record:</strong> {subdomain.ptr_record}<br />
                        <br />
                      </div>
                    ))
                  ) : (
                    <div>
                      <strong>Subdomain:</strong> {domain.subdomains.subdomain}<br />
                      <strong>CNAME Record:</strong> {domain.subdomains.cname_record}<br />
                      <strong>A Record:</strong> {domain.subdomains.a_record}<br />
                      <strong>MX Record:</strong> {domain.subdomains.mx_record}<br />
                      <strong>TXT Record:</strong> {domain.subdomains.txt_record}<br />
                      <strong>DMARC Record:</strong> {domain.subdomains.dmarc_record}<br />
                      <strong>DKIM Record:</strong> {domain.subdomains.dkim_record}<br />
                      <strong>PTR Record:</strong> {domain.subdomains.ptr_record}<br />
                      <br />
                    </div>
                  )}
                </td>
                <td style={tableCellStyle}>{domain.expiring_soon ? 'Yes' : 'No'}</td>
                <td style={tableCellStyle}>{domain.sold ? 'Yes' : 'No'}</td>
                <td style={tableCellStyle}>{domain.blocked}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      </div>
    </div>
  );
};

const tableCellStyle = {
  border: '1px solid #676767',
  padding: '8px',
  textAlign: 'center',
};

const tableHeaderStyle = {
  border: '1px solid #676767',
  backgroundColor: '#f2f2f2',
  padding: '8px',
  textAlign: 'center',
};

const preStyle = {
  margin: '0',
};

export default WhoisChecker;
