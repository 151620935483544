import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  AcademyAllData: [],
  loading: false,
  filled: false,
  error: null,
};

const tableSlice = createSlice({
  name: 'tableAcademyAll',
  initialState,
  reducers: {
    fetchTableRequest: state => {
      state.loading = true;
      state.error = null;
    },
    fetchTableSuccess: (state, action) => {
      state.loading = false;
      state.AcademyAllData = action.payload;
      state.filled = true;
    },
    fetchTableFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    modifyTableData: (state, action) => {
      const { id, columnName, newValue } = action.payload;
      const index = state.AcademyAllData.findIndex(row => row.id === id);
      if (index !== -1) {
        state.AcademyAllData[index][columnName] = newValue;
      }
    },
    addTableRow: (state, action) => {
      state.AcademyAllData.push(action.payload);
    },
    deleteTableRow: (state, action) => {
      const { id } = action.payload;
      state.AcademyAllData = state.AcademyAllData.filter(row => row.id !== id);
    },
  },
});

export const {
  fetchTableRequest,
  fetchTableSuccess,
  fetchTableFailure,
  modifyTableData,
  addTableRow,
  deleteTableRow,
} = tableSlice.actions;

export default tableSlice.reducer;
