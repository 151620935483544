import { configureStore } from '@reduxjs/toolkit';
import SettingsSlice from '../reducers/SettingsSlice';
import historyReducer from '../reducers/historyReducer';
import tableReducer from '../reducers/tableReducer';
import UsersReducer from '../reducers/UsersReducer';
import UsersAcademyReducer from '../reducers/UsersAcademyReducer';
import tableAcademyReducer from '../reducers/tableAcademyReducer';
import AcademyAllReducer from '../reducers/AcademyAllReducer';
import parentReducer from '../reducers/parentReducer';

const store = configureStore({
  reducer: {
    settings: SettingsSlice,
    table: tableReducer,
    tableAcademy: tableAcademyReducer,
    history: historyReducer,
    usersData: UsersReducer,
    usersAcademyData: UsersAcademyReducer,
    parentDomains: parentReducer,
    AcademyAllTable: AcademyAllReducer,
  },
  devTools: true
});

export default store;