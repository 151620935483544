import {
  fetchTableRequest,
  fetchTableSuccess,
  fetchTableFailure,
  modifyTableData,
  //addTableRow,
  deleteTableRow,
} from '../reducers/tableAcademyReducer';
import { academyDomains,
  //actions with table
  updateAcademy,
  addNewRow,
  deleteRowFromAcademy } from '../services/api';

export const fetchTableData = (tableName) => {
  return async (dispatch) => {
    dispatch(fetchTableRequest());

    try {
      let response;
      switch (tableName) {
        case 'Exam':
          response = await academyDomains();
          break;
        default:
          throw new Error(`Unknown table name: ${tableName}`);
      }
      const rowsWithMarketerId200 = response.filter(row => row.Marketer_ID === 200);
        const otherRows = response.filter(row => row.Marketer_ID !== 200);
  
        const sortedTableData = [...otherRows, ...rowsWithMarketerId200];

        const tableDataWithIndex = sortedTableData.map((row, index) => ({
          ...row,
          index: index + 1,
          lines: Math.max(
            ...Object.values(row).map(value =>
              typeof value === 'string' ? value.split('\n').length : 1
            )
          ),
        }));
      dispatch(fetchTableSuccess({ tableData: tableDataWithIndex, tableName }));
    } catch (error) {
      dispatch(fetchTableFailure(error.message));
    }
  };
};

export const updateTableData = (id, columnName, newValue) => {
  return async (dispatch) => {
    try{
      await updateAcademy(id, columnName, newValue);//api update
      dispatch(modifyTableData({ id, columnName, newValue }));//update in table
      //dispatch(fetchTableData());//upgrade table from DB
    }
    catch (error){
      console.error('Error updating data:', error);
    }
  };
};

export const updateLocalAcademyTableData = (id, columnName, newValue) => {
  return async (dispatch) => {
    try{
      if (columnName === 'Domain'){
        dispatch(fetchTableData("Exam"));
      }
      dispatch(modifyTableData({ id, columnName, newValue }));//update in table
    }
    catch (error){
      console.error('Error updating data locally:', error);
    }
  };
};


export const addNewTableRow = (newRowData) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      //const tableName = state.table.tableName;

      const tableData = state.table.tableData || [];

      const maxId = tableData.reduce((max, row) => (row.id > max ? row.id : max), 0);
      const maxIndex = tableData.reduce((max, row) => (row.index > max ? row.index : max), 0);

      const newRowDataWithId = { ...newRowData, id: maxId + 1, lines: 2, index: maxIndex + 1 };

      await addNewRow(newRowDataWithId); // Send to server
      //dispatch(addTableRow(newRowDataWithId)); // Data to Redux Store
      console.log(newRowDataWithId);
      //dispatch(fetchTableData(tableName)); // Update table
    } catch (error) {
      console.error('Error adding new row:', error);
    }
  };
};

export const addNewlocalTableRow = (newRowData) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      //dispatch(addTableRow(newRowData)); // Data to Redux Store
      const tableName = state.table.tableName
      console.log(newRowData);
      dispatch(fetchTableData(tableName)); // Update table
    } catch (error) {
      console.error('Error adding new row:', error);
    }
  };
};

export const deleteByIdTableRow = (id) => {
  return async (dispatch) => {
    try {
      await deleteRowFromAcademy(id);
      console.log(id);
      dispatch(deleteTableRow({id: id}));
      //dispatch(fetchTableData());
    } catch (error) {
      console.error('Error adding new row:', error);
    }
  };
};
