import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTableData, updateTableData, addNewTableRow, deleteByIdTableRow } from '../../actions/tableActions';
import VirtualizedTable from './VirtualizedTableRenderer'; // Путь к вашему компоненту

export default function Table({ tableName, currentUser, userTeam, searchValue, menuParentOptions, menuTeamOptions }) {
  const dispatch = useDispatch();
  const data = useSelector(state => state.table.tableData);
  const loading = useSelector(state => state.table.loading);
  const error = useSelector(state => state.table.error);
  useEffect(() => {
    dispatch(fetchTableData(tableName));
  }, [dispatch, tableName]);

  const columns = [
    { name: 'index', header: '#', width: 40, },
    { name: 'Domain', header: 'Domain', width: 220, editable: true, },
    { name: 'RegistrarAccount', header: 'Registrar/Account', width: 300, editable: true, },
    { name: 'Webhost', header: 'Webhost', width: 300, editable: true, },
    { name: 'Cloudflare', header: 'Cloudflare', width: 300, editable: true, },
    { name: 'PenName', header: 'Pen Name', width: 300, editable: true, },
    { name: 'ESP', header: 'ESP', width: 300, editable: true, },
    { name: 'Address', header: 'Address', width: 300, editable: true, },
    { name: 'ESPFromEmail', header: 'ESP From Email', width: 300, editable: true, },
    { name: 'EspReplyToEmail', header: 'ESP Reply To Email', width: 300, editable: true, },
    { name: 'SupportEmails', header: 'Support Emails', width: 300, editable: true, },
    { name: 'Password', header: 'Password', width: 300, editable: true, },
    { name: 'CpanelAccess', header: 'Cpanel Access', width: 300, editable: true, },
    { name: 'WpAccess', header: 'Wp Access', width: 300, editable: true, },
    { name: 'GoogleAnalytics', header: 'Google Analytics', width: 300, editable: true, },
    { name: 'GooglePostmaster', header: 'Google Postmaster', width: 300, editable: true, },
    { name: 'GoogleSearchConsole', header: 'Google Search Console', width: 300, editable: true, },
    { name: 'Comments', header: 'Comments', width: 300, editable: true, },
    { name: 'Parent', header: 'Parent', width: 300, editable: true, },
    { name: 'Team', header: 'Team', width: 300, editable: true, },
  ];

  const filteredData = () => {
    let filteredData = [...data];
    if (searchValue !== '') {
      filteredData = filteredData.filter((row) =>
        columns.some((column) => {
          const value = row[column.name];
          return (
            value !== null &&
            value !== undefined &&
            typeof value === 'string' &&
            value.toLowerCase().includes(searchValue.toLowerCase())
          );
        })
      );
    }
    return filteredData.length > 0 ? filteredData : [{ id: null, message: 'No matching results found' }];
  };

  return (
    <VirtualizedTable
      rows={filteredData()}
      loading={loading}
      error={error}
      columns={columns}
      currentUser={currentUser}
      userTeam={userTeam}
      menuTeamOptions={menuTeamOptions}
      menuParentOptions={menuParentOptions}
      updateTableData={updateTableData}
      addNewTableRow={addNewTableRow}
      deleteByIdTableRow={deleteByIdTableRow}
    />
  );
}