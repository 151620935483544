import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ParentData: [],
  loading: false,
  filled: false,
  error: null,
};

const parentSlice = createSlice({
  name: 'parentDomains',
  initialState,
  reducers: {
    fetchTableRequest: state => {
      state.loading = true;
      state.error = null;
    },
    fetchTableSuccess: (state, action) => {
      state.loading = false;
      state.ParentData = action.payload;
      state.filled = true;
    },
    fetchTableFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchTableRequest,
  fetchTableSuccess,
  fetchTableFailure,
} = parentSlice.actions;

export default parentSlice.reducer;
